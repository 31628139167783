import React from "react";
import moment from "moment-timezone";

import { useDefaultTimezone } from "hooks/useDefaultTimezone";

interface ChatWidgetTimestampProps {
	date: Date;
}

const ChatWidgetTimestamp: React.FunctionComponent<
	ChatWidgetTimestampProps
> = ({ date }) => {
	const timezone = useDefaultTimezone();
	const mDate = moment(date).tz(timezone);
	const isToday = mDate
		.clone()
		.startOf("day")
		.isSame(
			moment()
				.tz(timezone)
				.startOf("day")
		);
	return (
		<span className="rcw-timestamp">
			{mDate.format(`${!isToday ? `D MMMM YYYY, ` : ``}HH:mm`)}
		</span>
	);
};

export default ChatWidgetTimestamp;
