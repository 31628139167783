import React, { useState, useEffect } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import {
	DataInFreshlyEnteredRow,
	getDayOfWeekOptions
} from "components/PageDashboard/AvailabilitySection/AvailabilityTable/AvailabilityTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

function StepDaysOfWeek({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<DataInFreshlyEnteredRow>) {
	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(
		getDayOfWeekOptions(
			wizardData.dayOfWeekLocaltime ? wizardData.dayOfWeekLocaltime : []
		)
	);

	useEffect(() => {
		const dayOfWeekLocaltime = currentOptions
			.filter(currentOption => !!currentOption.selected)
			.map(o => parseInt(o.value + ""));

		if (dayOfWeekLocaltime.length) {
			enableProgressToNextStep({
				dayOfWeekLocaltime
			});
		} else {
			disableProgressToNextStep();
		}
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<FieldSelect
			allowMultipleSelections
			currentOptions={currentOptions}
			onNewCurrentOptions={setCurrentOptions}
		/>
	);
}

export default StepDaysOfWeek;
