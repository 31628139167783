import React, {
	useEffect,
	useCallback,
	useState,
	useContext,
	useMemo
} from "react";

import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";
import { OutputRow as MeetingRequestsData } from "api/getMeetingRequests";
import { markMeetingAsAwaitingWebhook } from "api/markMeetingAsAwaitingWebhook";
import { ApiRequest } from "api/ApiRequest";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import PaymentWizard from "components/PaymentWizard";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";

interface Props {
	payingForEvent: MeetingsDataFlattened | undefined;
	setPayingForEvent: React.Dispatch<
		React.SetStateAction<MeetingsDataFlattened | undefined>
	>;
	isWaiting: boolean;
	isLoading: boolean;
	isError: boolean;
	events: MeetingsDataFlattened[] | undefined;
}

const Payment: React.FunctionComponent<Props> = ({
	setPayingForEvent,
	payingForEvent,
	isWaiting,
	isLoading,
	isError,
	events
}) => {
	const { id: payingForEventId, singleSessionPrice, hostUid } = payingForEvent
		? payingForEvent
		: {
				id: undefined,
				singleSessionPrice: undefined,
				hostUid: undefined
		  };
	const { price, currency } = singleSessionPrice
		? singleSessionPrice
		: { price: undefined, currency: undefined };
	const paymentDetailsForPaymentWizard = useMemo(
		() =>
			price && currency && payingForEventId
				? {
						price,
						currency,
						meetingId: payingForEventId
				  }
				: undefined,
		[price, currency, payingForEventId]
	);

	const handleCancelPaymentWizard = useCallback(
		() => setPayingForEvent(undefined),
		[setPayingForEvent]
	);

	const [
		markMeetingAsAwaitingWebhookRequest,
		setMarkMeetingAsAwaitingWebhookRequest
	] = useState<ApiRequest<{}>>();
	useEffect(() => {
		return () => {
			if (markMeetingAsAwaitingWebhookRequest) {
				markMeetingAsAwaitingWebhookRequest.abort();
			}
		};
	}, [markMeetingAsAwaitingWebhookRequest]);

	const [{ uid }] = useContext(AuthContext);
	const [, setDashboardContextState] = useContext(DashboardContext);

	const handlePaidByCard = useCallback(async () => {
		if (!(payingForEventId && typeof payingForEventId === "string")) {
			throw new Error("No valid payingForEventId");
		}

		const req = markMeetingAsAwaitingWebhook({ id: payingForEventId }, { uid });
		setMarkMeetingAsAwaitingWebhookRequest(req);
		await req.ready;

		setDashboardContextState(oldState => ({
			...oldState,
			meetingsSectionRefreshNum: new Date().getTime()
		}));
		setPayingForEvent(undefined);
	}, [setDashboardContextState, setPayingForEvent, payingForEventId, uid]);

	const handlePayingOutsideTheSystem = useCallback(async () => {
		setPayingForEvent(undefined);
	}, [setPayingForEvent]);

	const handlePaymentFailed = useCallback(async () => {
		setDashboardContextState(oldState => ({
			...oldState,
			meetingsSectionRefreshNum: new Date().getTime()
		}));
		setPayingForEvent(undefined);
	}, [setDashboardContextState, setPayingForEvent]);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<MeetingsDataFlattened | MeetingRequestsData>({
		key: "meetingPayFor",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: events
		},
		handleFoundItem: useCallback(
			event => {
				if ("paymentStatus" in event && event.paymentStatus === "pending") {
					setPayingForEvent(event);
				}
				stripQueryStringFromAddressBar();
			},
			[setPayingForEvent, stripQueryStringFromAddressBar]
		)
	});

	return (
		<PaymentWizard
			mentorId={hostUid}
			metadata={payingForEventId ? { meetingId: payingForEventId } : undefined}
			paymentDetails={paymentDetailsForPaymentWizard}
			onCancel={handleCancelPaymentWizard}
			onPaidByCard={handlePaidByCard}
			onPayingOutsideTheSystem={handlePayingOutsideTheSystem}
			onFailed={handlePaymentFailed}
		/>
	);
};

export default Payment;
