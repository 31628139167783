import React from "react";

import terminology from "terminology.json";
import { MeetingCalendarProps } from "./MeetingCalendar";
import ChangeTimezoneButton from "./ChangeTimezoneButton";
import {
	KeyOuter,
	KeyAvailable,
	KeyBooked,
	KeyUnavailable,
	KeyUserBusy,
	KeyUserBooked,
	KeyAwaitingMentorConfirmation,
	KeyTimezone,
	KeyCompactDisplayModeSecondRow
} from "./Key.styles";

interface Props {
	displaymode: MeetingCalendarProps["displaymode"];
	isSignedIn: MeetingCalendarProps["isSignedIn"];
	timezone: string;
}

const Key: React.FunctionComponent<Props> = ({
	displaymode,
	isSignedIn,
	timezone
}) => {
	return (
		<KeyOuter>
			<KeyAvailable>available</KeyAvailable>
			{displaymode === "compact" ? null : (
				<>
					<KeyBooked>booked</KeyBooked>
					<KeyUnavailable>not available</KeyUnavailable>
					{isSignedIn ? <KeyUserBusy>you are busy</KeyUserBusy> : null}
					{isSignedIn ? <KeyUserBooked>booked by you</KeyUserBooked> : null}
					{isSignedIn ? (
						<KeyAwaitingMentorConfirmation>
							awaiting {terminology.mentor} confirmation
						</KeyAwaitingMentorConfirmation>
					) : null}
				</>
			)}
			<KeyTimezone>
				{displaymode === "compact" ? null : `Times as per: `}
				{timezone} {displaymode === "compact" ? null : <ChangeTimezoneButton />}
			</KeyTimezone>
			{displaymode === "compact" ? (
				<KeyCompactDisplayModeSecondRow>
					<ChangeTimezoneButton />
				</KeyCompactDisplayModeSecondRow>
			) : null}
		</KeyOuter>
	);
};

export default Key;
