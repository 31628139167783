import React, { useCallback, useState, useRef, useEffect } from "react";
import moment from "moment";

import Button from "components/Button";
import ErrorBanner from "components/ErrorBanner";
import PageContent from "components/PageContent";
import MentorProfilePreviewLink from "components/MentorProfilePreviewLink";
import FilterButton from "components/PageMentorsIndex/FilterButton";
import ModalFieldSelect from "components/ModalFieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import { useRemoteResource } from "hooks/useRemoteResource";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";
import { searchForMentors } from "api/searchForMentors";
import { SearchResultsWithCount } from "api/SearchResults";
import { Output as ProfileData } from "api/getUserProfile";
import { getMentorSearchTerms, Term } from "api/getMentorSearchTerms";
import { ucFirst } from "utils/ucFirst";
import terminology from "terminology.json";

import { ShowMoreButton, FilterButtons } from "./PageMentorsIndex.styles";

interface ProfileSearchResultsEntry extends ProfileData {
	sortindex: number;
}

const PageMentorsIndex: React.FunctionComponent = () => {
	usePageTitlePrefix(ucFirst(terminology.mentors));

	const [cursor, setCursor] = useState<number | undefined>(undefined);
	const [allLoadedResults, setAllLoadedResults] = useState<
		ProfileSearchResultsEntry[]
	>([]);

	const {
		isWaiting: searchTermWaiting,
		isLoading: searchTermLoading,
		isError: searchTermError,
		output: searchTermResults
	} = useRemoteResource<Term[]>(getMentorSearchTerms);

	const showMore = useCallback(
		(lastSortIndex: number) => {
			setCursor(lastSortIndex);
		},
		[setCursor]
	);

	const isSmallScreen = useScreenSizeIsMax("small");

	const [smallScreenFiltersShowing, setSmallScreenFiltersShowing] = useState(
		false
	);

	const [activeLocationFilters, setActiveLocationFilters] = useState<
		FieldOption[]
	>([]);
	const [activeCanHelpWithFilters, setActiveCanHelpWithFilters] = useState<
		FieldOption[]
	>([]);
	const [
		activeSpecialistKnowledgeFilters,
		setActiveSpecialistKnowledgeFilters
	] = useState<FieldOption[]>([]);

	const chooseNewLocationFilters = useCallback(
		(newOptions: FieldOption[]) => {
			setActiveLocationFilters(newOptions.filter(opt => !!opt.selected));
		},
		[setActiveLocationFilters]
	);

	const chooseNewCanHelpWithFilters = useCallback(
		(newOptions: FieldOption[]) => {
			const newActiveFilters = newOptions.filter(opt => !!opt.selected);
			setActiveCanHelpWithFilters(newActiveFilters);
		},
		[setActiveCanHelpWithFilters]
	);

	const chooseNewSpecialistKnowledgeFilters = useCallback(
		(newOptions: FieldOption[]) => {
			setActiveSpecialistKnowledgeFilters(
				newOptions.filter(opt => !!opt.selected)
			);
		},
		[setActiveSpecialistKnowledgeFilters]
	);

	const showSmallScreenFilters = useCallback(
		() => setSmallScreenFiltersShowing(true),
		[setSmallScreenFiltersShowing]
	);

	const getFieldOptions = (
		category: string,
		activeFilters: FieldOption[]
	): FieldOption[] => {
		return (searchTermResults
			? searchTermResults
					.filter(r => r.category === category)
					.map(r => ({
						id: r.value,
						text: ucFirst(r.text),
						value: r.value,
						selected: activeFilters.some(o => o.value === r.value)
					}))
			: []
		).sort((a, b) => Intl.Collator().compare(a.text, b.text));
	};

	const params = JSON.stringify(
		[
			...activeLocationFilters,
			...activeCanHelpWithFilters,
			...activeSpecialistKnowledgeFilters
		].map(f => f.value + "")
	);
	useEffect(() => {
		setCursor(undefined);
		setAllLoadedResults([]);
	}, [setCursor, setAllLoadedResults, params]);
	const currentFilters = {
		...(activeLocationFilters.length
			? {
					location: activeLocationFilters.map(f => JSON.parse(f.value + ""))[0]
			  }
			: {}),
		canhelpwith: activeCanHelpWithFilters.map(f => f.value + ""),
		specialistknowledge: activeSpecialistKnowledgeFilters.map(f => f.value + "")
	};
	if (currentFilters.location) {
		if (
			!(
				currentFilters.location.locationDistrict &&
				currentFilters.location.locationCountryCode
			)
		) {
			throw new Error("Invalid location filter");
		}
	}
	const filters = useRef<{
		location?: { locationDistrict: string; locationCountryCode: string };
		canhelpwith: string[];
		specialistknowledge: string[];
	}>(currentFilters);
	filters.current = currentFilters;
	const doSearch = useCallback(
		(arg, auth) => {
			const { location, canhelpwith, specialistknowledge } = filters.current;

			if (params) {
				// Do nothing (this removes React warning about unused dependency.
				// (In fact, the 'params' dependency is required because it changes when the contents of filters.current changes, prompting
				// this callback to regenerate and consequently the search to be re-run)
			}

			const response = searchForMentors(
				{
					cursor: cursor ? cursor : undefined,
					cursorType: "after",
					...(location ? { location } : {}),
					canhelpwith,
					specialistknowledge
				},
				auth
			);

			return response;
		},
		[cursor, filters, params]
	);

	const [repeatTimestamp, setRepeatTimestamp] = useState(1);
	const { isError, output: searchResults } = useRemoteResource<
		SearchResultsWithCount<ProfileSearchResultsEntry>
	>(doSearch, false, repeatTimestamp);

	const tryAgain = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			setRepeatTimestamp(new Date().getTime());
		},
		[setRepeatTimestamp]
	);

	const currentResults = useRef(searchResults);
	currentResults.current = searchResults;

	const lastSortIndex = searchResults
		? getLastSortIndex(searchResults)
		: undefined;

	useEffect(() => {
		if (lastSortIndex) {
			const { current } = currentResults;
			if (current && current.page && current.page.length) {
				setAllLoadedResults(oldState => {
					// Remove duplicates from the new state (this is necessary to avoid a whole page of duplicates arriving after you log in or log out while on this page)
					// TODO:WV:20210430:Refactor this whole component; it is a mess/
					return [...oldState, ...current.page].reduce(
						(agg, cur) => [
							...agg,
							...(agg.some(el => el.uid === cur.uid) ? [] : [cur])
						],
						[] as ProfileSearchResultsEntry[]
					);
				});
			}
		}
	}, [lastSortIndex, setAllLoadedResults, currentResults]);

	const handleClickShowMore = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			showMore(
				moment(lastSortIndex)
					.toDate()
					.getTime()
			);
		},
		[showMore, lastSortIndex]
	);

	const countRef = useRef<number | undefined>(undefined);
	if (searchResults && searchResults.count !== undefined) {
		countRef.current = searchResults.count;
	}
	const { current: count } = countRef;

	const haveNextPage = searchResults
		? searchResults.nextPage && searchResults.nextPage.length
		: undefined;
	const searchTermsButtonsDisabled =
		searchTermWaiting || searchTermLoading || searchTermError;

	return (
		<PageContent>
			<FilterButtons className="actions">
				{isSmallScreen && !smallScreenFiltersShowing ? (
					<FilterButton
						disabled={searchTermsButtonsDisabled}
						onClick={showSmallScreenFilters}
						title="Filter results"
						numActive={0}
					/>
				) : null}
				{!isSmallScreen || smallScreenFiltersShowing ? (
					<>
						<ModalFieldSelect
							isUnlocked={!searchTermsButtonsDisabled}
							title="Location"
							onOK={chooseNewLocationFilters}
							options={getFieldOptions("location", activeLocationFilters)}
							allowMultipleSelections={false}
							editButton={({ onClick, isUnlocked }) => (
								<FilterButton
									disabled={!isUnlocked}
									onClick={onClick}
									title="Location"
									numActive={activeLocationFilters.length}
								/>
							)}
						/>

						<ModalFieldSelect
							isUnlocked={!searchTermsButtonsDisabled}
							title="Teaches"
							onOK={chooseNewCanHelpWithFilters}
							options={getFieldOptions("canhelpwith", activeCanHelpWithFilters)}
							editButton={({ onClick, isUnlocked }) => (
								<FilterButton
									disabled={!isUnlocked}
									onClick={onClick}
									title="Teaches"
									numActive={activeCanHelpWithFilters.length}
								/>
							)}
						/>

						<ModalFieldSelect
							isUnlocked={!searchTermsButtonsDisabled}
							title="Speaks"
							onOK={chooseNewSpecialistKnowledgeFilters}
							options={getFieldOptions(
								"specialistknowledge",
								activeSpecialistKnowledgeFilters
							)}
							editButton={({ onClick, isUnlocked }) => (
								<FilterButton
									disabled={!isUnlocked}
									onClick={onClick}
									title="Speaks"
									numActive={activeSpecialistKnowledgeFilters.length}
								/>
							)}
						/>
					</>
				) : null}
			</FilterButtons>

			<p>
				{count === undefined ? null : (
					<>
						<strong>{count}</strong>{" "}
						{count === 1 ? terminology.mentor : terminology.mentors} found
					</>
				)}
			</p>
			{allLoadedResults.map(profileData => (
				<MentorProfilePreviewLink
					key={`profile-link-${profileData.uid}`}
					vanityUrl={profileData.vanityUrl}
				/>
			))}
			{isError ? <ErrorBanner /> : null}

			<ul className="actions">
				{isError ? (
					<li>
						<Button onClick={tryAgain} label="Try again" />
					</li>
				) : null}
				<li>
					<ShowMoreButton
						className={`button ${
							!(haveNextPage && lastSortIndex && !isError) ? "x-hidden" : ""
						}`}
						onClick={handleClickShowMore}
					>
						Show more
					</ShowMoreButton>
				</li>
			</ul>
		</PageContent>
	);
};

export default PageMentorsIndex;

function getLastSortIndex(
	searchResults: SearchResultsWithCount<ProfileSearchResultsEntry> | undefined
) {
	return searchResults && searchResults.page.length
		? searchResults.page.slice(-1)[0].sortindex
		: undefined;
}
