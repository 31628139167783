import moment from "moment-timezone";

import { OutputRow as MeetingRescheduleRequestsData } from "api/getMeetingRescheduleRequests";

export function formatMeetingRescheduleRequestDetails(
	event: MeetingRescheduleRequestsData,
	currentUid: string,
	useShortFormat: boolean,
	timezone: string
) {
	const {
		hostUid,
		guestUid,
		dateStartsUTC,
		dateEndsJustBeforeUTC,
		originalDateStartsUTC,
		originalDateEndsJustBeforeUTC
	} = event;

	const start = moment(dateStartsUTC).tz(timezone);
	const end = moment(dateEndsJustBeforeUTC).tz(timezone);

	const originalStart = moment(originalDateStartsUTC).tz(timezone);
	const originalEnd = moment(originalDateEndsJustBeforeUTC).tz(timezone);

	const partnersUid = hostUid === currentUid ? guestUid : hostUid;
	const partnersName =
		hostUid === currentUid ? event.displayNameGuest : event.displayNameHost;

	const formatStringDate = useShortFormat ? "YYYY/MM/DD" : "dddd MMMM Do YYYY";
	const formatStringTime = "HH:mm";

	return {
		hostUid,
		guestUid,
		startDate: start.format(formatStringDate),
		startTime: start.format(formatStringTime),
		endTime: end.format(formatStringTime),
		originalStartDate: originalStart.format(formatStringDate),
		originalStartTime: originalStart.format(formatStringTime),
		originalEndTime: originalEnd.format(formatStringTime),
		timezone,
		partnersName: partnersName ? partnersName : "(unknown)",
		identifier: `${event.hostUid}-${event.guestUid}-${start.format(
			"X"
		)}-${end.format("X")}`,
		partnersUid
	};
}
