import styled from "styled-components";

import {
	eventColorAvailable,
	eventColorPending,
	eventColorBooked,
	eventColorUserBusy,
	stripedBackground
} from "./MeetingCalendar.styles";

export const KeyOuter = styled.ul`
	padding-left: 0;
	li {
		display: inline-block;
		padding-left: 0;
		padding-right: 1em;
	}
	li::before {
		content: "";
		width: 1em;
		height: 1em;
		display: inline-block;
		vertical-align: middle;
		margin-right: 0.5em;
		opacity: 0.4;
	}
`;

export const KeyAvailable = styled.li`
	&:before {
		background-color: ${eventColorAvailable};
	}
`;
export const KeyBooked = styled.li`
	&:before {
		background-color: ${eventColorBooked};
	}
`;
export const KeyUnavailable = styled.li`
	&:before {
		border: 1px solid #ccc;
	}
`;
export const KeyUserBooked = styled.li`
	&:before {
		${stripedBackground(eventColorAvailable)};
	}
`;
export const KeyUserBusy = styled.li`
	&:before {
		background-color: ${eventColorUserBusy};
	}
`;
export const KeyAwaitingMentorConfirmation = styled.li`
	&:before {
		${stripedBackground(eventColorPending)};
	}
`;
export const KeyTimezone = styled.li`
	&:before {
		content: "🕑" !important;
		color: #777777;
		opacity: 1 !important;
		line-height: 0.75em;
	}
`;

export const KeyCompactDisplayModeSecondRow = styled.div`
	margin-top: 0.5em;
`;
