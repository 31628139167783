import { getVisibleSteps } from "./getVisibleSteps";
import { ModalWizardStep } from "components/ModalWizard/ModalWizard";
import { ModalWizardFormState } from "./useModalWizard";

export function getStepCount<CompletedWizardData>(
	allVisibleSteps: ModalWizardStep<CompletedWizardData>[],
	formState: ModalWizardFormState<CompletedWizardData>
) {
	const stepIndex = allVisibleSteps.findIndex(
		el => el.key === formState.currentStepKey
	);

	const laterVisibleSteps = getVisibleSteps(
		allVisibleSteps.slice(stepIndex + 1),
		formState.wizardData
	);

	const stepNumber =
		allVisibleSteps.filter(step => formState.completedSteps.includes(step.key))
			.length + 1;
	const totalNumSteps = stepNumber + laterVisibleSteps.length;

	return {
		stepNumber,
		totalNumSteps
	};
}
