import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";

import FieldSelect from "components/FieldSelect";
import {
	FieldOption,
	FieldSelectProps
} from "components/FieldSelect/FieldSelect";
import ModalConfirm from "components/ModalConfirm";
import { EditButtonRenderProps } from "components/ModalField/ModalField";
import ToggleableButton from "components/ToggleableButton";
import { useScrollToInitialPosition } from "hooks/useScrollToInitialPosition";

type ModalFieldSelectProps = Omit<
	Omit<FieldSelectProps, "currentOptions">,
	"onNewCurrentOptions"
> & {
	onCancel?: () => void;
	onOK: (newOptions: FieldOption[]) => void;
	onOpen?: () => void;
	onClose?: () => void;
	options: FieldOption[];
	title: string;
	isUnlocked?: boolean;
	formatSelectedOption?: (selectedOption: FieldOption) => string;
	editButton?: (props: EditButtonRenderProps) => React.ReactNode;
	requireSelection?: boolean;
	allowWrapTextInButton?: boolean;
	useTallButton?: boolean;
	useTertiaryButton?: boolean;
	pagination?: {
		havePreviousPage: boolean;
		haveNextPage: boolean;
		onClickNextPage: () => void;
		onClickPreviousPage: () => void;
	};
};

function ModalFieldSelect({
	onCancel = () => undefined,
	onOK,
	onOpen,
	onClose,
	title,
	options,
	allowMultipleSelections = true,
	isLoading,
	isError,
	isUnlocked = true,
	formatSelectedOption = selectedOption => selectedOption.text,
	editButton,
	requireSelection = false,
	allowWrapTextInButton = true,
	useTallButton = false,
	useTertiaryButton,
	pagination,
	...rest
}: ModalFieldSelectProps) {
	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(options);

	const reset = useCallback(() => {
		setCurrentOptions(options);
	}, [options, setCurrentOptions]);

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (isOpen && onOpen) {
			onOpen();
		}
		if (!isOpen && onClose) {
			onClose();
		}
	}, [isOpen, onOpen, onClose]);

	const toggleIsOpen = useCallback(
		(e: any) => {
			setIsOpen(oldIsOpen => !oldIsOpen);
			e.preventDefault();
		},
		[setIsOpen]
	);
	useEffect(() => {
		reset();
	}, [isOpen, reset]);

	const scrollToInitialPosition = useScrollToInitialPosition();
	const [isNotValid, setIsNotValid] = useState(false);

	const previousOptions = useRef<FieldOption[]>([]);
	useEffect(() => {
		const currentSelectedOptions = currentOptions.filter(val => !!val.selected);
		const previousSelectedOptions = previousOptions.current.filter(
			val => !!val.selected
		);

		const selectedOptionsHaveChanged =
			currentSelectedOptions.length !== previousSelectedOptions.length ||
			!currentSelectedOptions.every(val =>
				previousSelectedOptions.includes(val)
			);
		if (selectedOptionsHaveChanged) {
			setIsNotValid(false);
		}

		previousOptions.current = currentOptions;
	}, [currentOptions, previousOptions]);

	const handleOK = useCallback(() => {
		const selectedOption = currentOptions.find(val => !!val.selected);
		if (requireSelection) {
			if (!selectedOption) {
				setIsNotValid(true);
				return;
			}
		}
		if (
			selectedOption &&
			selectedOption.isOtherField &&
			(selectedOption.value === undefined || selectedOption.value === "")
		) {
			setIsNotValid(true);
			return;
		}
		onOK(currentOptions);
		setIsOpen(false);
	}, [onOK, currentOptions, setIsOpen, requireSelection, setIsNotValid]);

	const handleCancel = useCallback(() => {
		onCancel();
		setIsOpen(false);
	}, [onCancel, setIsOpen]);

	const displayValue = useMemo(() => {
		const selectedOptions = options.filter(opt => !!opt.selected);
		return selectedOptions.length
			? selectedOptions.map(formatSelectedOption).join(", ")
			: title;
	}, [options, formatSelectedOption, title]);

	return (
		<>
			{editButton ? (
				editButton({ isUnlocked, onClick: toggleIsOpen })
			) : (
				<ToggleableButton
					isEditing={isUnlocked}
					icon={faCaretDown}
					onClick={toggleIsOpen}
					label={displayValue}
					allowWrap={allowWrapTextInButton}
					isTall={useTallButton}
					isTertiary={useTertiaryButton}
				/>
			)}
			<ModalConfirm
				isOpen={isOpen}
				title={title}
				onCancel={handleCancel}
				onOK={handleOK}
				onContents={scrollToInitialPosition}
			>
				<FieldSelect
					{...rest}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
					allowMultipleSelections={allowMultipleSelections}
					isNotValid={isNotValid}
					pagination={pagination}
					useCheckboxes
				/>
			</ModalConfirm>
		</>
	);
}

export default ModalFieldSelect;
