import React, { useState, useCallback, useEffect } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";
import { useDefaultTimezone } from "hooks/useDefaultTimezone";

import { TextareaContainer } from "./StepConfirmAndAddMessage.styles";
import SelectedEventSummary from "components/SelectedEventSummary";

interface CompletedWizardData {
	// If booking a package (rather than an individual meeting) the eventToBook will not be defined
	eventToBook: MeetingCalendarEvent;

	message: string;
}

function StepConfirmAndAddMessage({
	enableProgressToNextStep,
	wizardData
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const [message, setMessage] = useState("");
	const { eventToBook } = wizardData;
	const defaultTimezone = useDefaultTimezone();

	const handleNewValue = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			setMessage(e.currentTarget.value);
			enableProgressToNextStep({ message: e.currentTarget.value });
		},
		[setMessage, enableProgressToNextStep]
	);

	useEffect(() => {
		enableProgressToNextStep({});
	}, [enableProgressToNextStep]);

	return (
		<>
			{eventToBook ? (
				<SelectedEventSummary
					eventToBook={eventToBook}
					timezone={defaultTimezone}
				/>
			) : null}
			<TextareaContainer>
				<textarea
					placeholder="Your message"
					rows={4}
					value={message}
					onChange={handleNewValue}
				/>
			</TextareaContainer>
		</>
	);
}

export default StepConfirmAndAddMessage;
