import React, { useEffect, useState } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import { DataInAnyRow } from "components/PageDashboard/ICalFeedsToImportSection/ICalFeedsToImportTable/ICalFeedsToImportTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { getBooleanOptions } from "services/getBooleanOptions";

function StepContainsExternallyBookedMeetings({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(
		getBooleanOptions()
	);

	useEffect(() => {
		const selectedOption = currentOptions.find(
			currentOptions => !!currentOptions.selected
		);
		if (!selectedOption) {
			disableProgressToNextStep();
			return;
		}

		if (typeof selectedOption.value !== "boolean") {
			throw new Error("Selected value was not a boolean value");
		}

		enableProgressToNextStep({
			containsExternallyBookedMeetings: selectedOption.value
		});
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<FieldSelect
			allowMultipleSelections={false}
			currentOptions={currentOptions}
			onNewCurrentOptions={setCurrentOptions}
			useCheckboxes={true}
		/>
	);
}

export default StepContainsExternallyBookedMeetings;
