import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import {
	ValidationCallback,
	validate,
	validationErrorsToString
} from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";
import {
	flagValidationErrorsInSessionType,
	SessionType
} from "types-and-validators/flagValidationErrorsInSessionType";
import {
	flagValidationErrorsInPackagePrice,
	PackagePrice
} from "types-and-validators/flagValidationErrorsInPackagePrice";
type PaymentStatus = "free" | "pending" | "awaiting-webhook" | "ok";

export interface OutputRow {
	id: string;
	hostUid: string;
	guestUid: string;
	displayNameHost: string;
	displayNameGuest: string;
	paymentStatus: PaymentStatus | undefined;
	packagePrice: PackagePrice;
	sessionType: SessionType;
	numAvailableCredits: number;
	numPendingCredits: number;
}

const flagValidationErrorsInRow: ValidationCallback<OutputRow> = (
	input,
	{ flag, checkField },
	{ uid }
) => {
	checkField("id", { type: "string" });

	const isOwnPackage: boolean =
		!!uid &&
		((!!input.hostUid && input.hostUid === uid) ||
			(!!input.guestUid && input.guestUid === uid));

	checkField("hostUid", { type: isOwnPackage ? "string" : "absent" });
	checkField("guestUid", { type: isOwnPackage ? "string" : "absent" });
	checkField("displayNameHost", { type: isOwnPackage ? "string" : "absent" });
	checkField("displayNameGuest", { type: isOwnPackage ? "string" : "absent" });
	checkField(
		"paymentStatus",
		isOwnPackage
			? {
					type: "enum",
					allowedValues: ["free", "pending", "awaiting-webhook", "ok"],
					isRequired: true
			  }
			: { type: "absent" }
	);

	validate(input.sessionType, {
		doValidate: flagValidationErrorsInSessionType,
		withErrors: errors => {
			flag("sessionType", validationErrorsToString(errors));
		},
		auth: { uid }
	});

	validate(input.packagePrice, {
		doValidate: flagValidationErrorsInPackagePrice,
		withErrors: errors => {
			flag("packagePrice", validationErrorsToString(errors));
		},
		auth: { uid }
	});

	checkField("numAvailableCredits", { type: "number" });
	checkField("numPendingCredits", { type: "number" });
};

const flagValidationErrors: ValidationCallback<SearchResults<OutputRow>> = (
	input,
	flag,
	auth
) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

interface Params {
	uid: string;
	isHost: boolean;
	isComplete: boolean;
	paymentStatus?: PaymentStatus;

	cacheInMemory?: boolean;
}

export function getPackages(
	{ uid, isHost, isComplete, paymentStatus, cacheInMemory }: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("packages", {
		cacheInMemory,
		auth,
		data: {
			get: {
				uid,
				isHost: isHost ? "1" : "0",
				isComplete: isComplete ? "1" : "0",
				...(paymentStatus ? { paymentStatus } : {})
			}
		},
		flagValidationErrors
	});

	return req;
}
