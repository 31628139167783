export type StripeAccountOnboardingStatus =
	| "linked"
	| "unlinked"
	| "account-requested";

export function isValidStripeAccountOnboardingStatus(
	input: unknown
): input is StripeAccountOnboardingStatus {
	return (
		input === "linked" || input === "unlinked" || input === "account-requested"
	);
}
