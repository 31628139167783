import React, { useCallback, useMemo } from "react";

import { OnEdit } from "components/EditableTable/EditableTable";
import ModalFieldSelect from "components/ModalFieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import terminology from "terminology.json";
import { ucFirst } from "utils/ucFirst";
import { useUserProfile } from "hooks/useUserProfile";

import { usePaginatedContacts } from "../../../../hooks/usePaginatedContacts";

type Props<T> = {
	isEditing: boolean;
	onEdit: OnEdit<T>;
	row: T;
	rowKey: keyof T;
};

function FieldMenteeUid<T extends { guestUid: string | null }>({
	isEditing,
	onEdit,
	row,
	rowKey
}: Props<T>) {
	const { isWaiting, isLoading, isError, output } = useUserProfile({
		uid: row.guestUid ? row.guestUid : undefined,
		cacheInMemory: true
	});

	const {
		haveNextPage,
		havePreviousPage,
		goToNextPage,
		goToPreviousPage,
		options
	} = usePaginatedContacts(
		row.guestUid,
		output && !(isWaiting || isLoading || isError)
			? output.displayName
			: undefined,
		"mentee"
	);

	const onOK = useCallback(
		(newOptions: FieldOption[]) => {
			const selectedOption = newOptions.find(val => !!val.selected);
			if (!selectedOption) {
				throw new Error("No selected option");
			}

			const newData = {
				...row,
				[rowKey]: selectedOption.value
			};
			onEdit([{ old: row, new: newData }]);
		},
		[onEdit, row, rowKey]
	);

	const pagination = useMemo(
		() => ({
			havePreviousPage,
			onClickPreviousPage: goToPreviousPage,
			haveNextPage,
			onClickNextPage: goToNextPage
		}),
		[havePreviousPage, goToPreviousPage, haveNextPage, goToNextPage]
	);

	return (
		<ModalFieldSelect
			isUnlocked={isEditing}
			title={ucFirst(terminology.mentee)}
			requireSelection
			onOK={onOK}
			options={options}
			allowMultipleSelections={false}
			pagination={pagination}
		/>
	);
}

export default FieldMenteeUid;
