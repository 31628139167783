import moment from "moment-timezone";

export function getTimezoneOffsetFromLocalTimezone(
	referenceDateTime: Date,
	targetTimezone: string
) {
	return (
		moment.tz(referenceDateTime, targetTimezone).utcOffset() -
		moment(referenceDateTime).utcOffset()
	);
}
