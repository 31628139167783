import { useState, useCallback, useContext } from "react";
import moment from "moment-timezone";
import flatten from "lodash.flatten";

// @ts-ignore
import chunk from "chunk-date-range";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import {
	MeetingCalendarProps,
	SlotDurationInMinutes
} from "components/MeetingCalendar/MeetingCalendar";
import { useScreenSizeIsMax } from "hooks/useScreenSizeIsMax";
import { useRemoteResource } from "hooks/useRemoteResource";
import { getMeetings } from "api/getMeetings";
import {
	getExternalCommitments,
	OutputRow as ExternalCommitmentsData
} from "api/getExternalCommitments";
import { makeDummyRequest } from "api/makeDummyRequest";
import { SearchResults } from "api/SearchResults";
import {
	getMeetingRequests,
	OutputRow as MeetingRequestsData
} from "api/getMeetingRequests";
import { getAvailability } from "api/getAvailability";
import { safeTsNotNullish } from "utils/safeTsNotNullish";
import { deDup } from "utils/deDup";
import { getAllDatesBetween } from "utils/datesAndTimes/getAllDatesBetween";
import { dateRangesOverlap } from "services/dateRangesOverlap";
import { getCutOffPeriodForBookingAndConfirmingMeetings } from "services/getCutOffPeriodForBookingAndConfirmingMeetings";

interface UseAvailabilityCalendarDataParams {
	mentorId?: string;
	slotDurationInMinutes: SlotDurationInMinutes;

	// This prop is for situations in which the data has already been fetched but the hook still needs to be included because of the rules of hooks (such as in the Availability component if the calendar data was provided to that component as a prop)
	dontFetch?: boolean;
}

export function useAvailabilityCalendarData({
	mentorId,
	slotDurationInMinutes,
	dontFetch
}: UseAvailabilityCalendarDataParams) {
	const isSmallScreen = useScreenSizeIsMax("small");
	const isMediumScreen = useScreenSizeIsMax("medium");
	const [weekOffset, setWeekOffset] = useState(0);

	const [startDateFromCalendar, setStartDateFromCalendar] = useState<
		Date | undefined
	>(undefined);
	const [endDateFromCalendar, setEndDateFromCalendar] = useState<
		Date | undefined
	>(undefined);
	const meetingCalendarHasNewDateRange = useCallback(
		(start: Date, end: Date) => {
			setStartDateFromCalendar(start);
			setEndDateFromCalendar(end);
		},
		[setStartDateFromCalendar, setEndDateFromCalendar]
	);

	const startDateFromCalendarMs = startDateFromCalendar
		? startDateFromCalendar.getTime()
		: undefined;
	const endDateFromCalendarMs = endDateFromCalendar
		? endDateFromCalendar.getTime()
		: undefined;

	const fetchEvents = useCallback(
		(arg, auth) => {
			if (!mentorId) {
				throw new Error("No mentor ID");
			}

			if (!(startDateFromCalendarMs && endDateFromCalendarMs)) {
				throw new Error("No valid date range");
			}

			const calendarStartDate = new Date(
				safeTsNotNullish(startDateFromCalendarMs)
			);
			const calendarEndDate = new Date(safeTsNotNullish(endDateFromCalendarMs));

			// TODO:WV:20200803:Limit length of output of this?
			const reqAvailability = getAvailability(
				{
					uid: mentorId,

					/* Cache in memory to prevent reload of calendar when open booking wizard from mentor profile */
					cacheInMemory: true
				},
				auth
			);

			const reqMeetings = getMeetings(
				{
					hostUid: mentorId,
					mustEndAfterUTC: calendarStartDate,
					mustStartBeforeUTC: calendarEndDate,

					/* Cache in memory to prevent reload of calendar when open booking wizard from mentor profile */
					cacheInMemory: true
				},
				auth
			);

			const reqMeetingRequests = getMeetingRequests(
				{
					hostUid: mentorId,
					mustEndAfterUTC: calendarStartDate,
					mustStartBeforeUTC: calendarEndDate,
					isAccepted: false,
					isRejected: false,

					/* Cache in memory to prevent reload of calendar when open booking wizard from mentor profile */
					cacheInMemory: true
				},
				auth
			);

			const reqExternalCommitments = getExternalCommitments(
				{
					uid: mentorId,
					mustEndAfterUTC: calendarStartDate,
					mustStartBeforeUTC: calendarEndDate,

					/* Cache in memory to prevent reload of calendar when open booking wizard from mentor profile */
					cacheInMemory: true
				},
				auth
			);

			const currentUserId = auth ? auth.uid : undefined;

			const reqThisUsersMeetings = currentUserId
				? getMeetings(
						{
							guestUid: currentUserId,
							mustEndAfterUTC: calendarStartDate,
							mustStartBeforeUTC: calendarEndDate,

							/* Cache in memory to prevent reload of calendar when open booking wizard from mentor profile */
							cacheInMemory: true
						},
						{ uid: currentUserId }
				  )
				: makeDummyRequest({ page: [] }, "meetings");

			const now = new Date();
			const reqThisUsersMeetingRequests = currentUserId
				? getMeetingRequests(
						{
							guestUid: currentUserId,
							mustEndAfterUTC:
								calendarStartDate.getTime() < now.getTime()
									? now
									: calendarStartDate,
							mustStartBeforeUTC: calendarEndDate,
							isAccepted: false,
							isRejected: false,

							/* Cache in memory to prevent reload of calendar when open booking wizard from mentor profile */
							cacheInMemory: true
						},
						{ uid: currentUserId }
				  )
				: makeDummyRequest<SearchResults<MeetingRequestsData>>(
						{
							page: []
						},
						"meetingrequests"
				  );

			const ready = Promise.all([
				reqAvailability.ready,
				reqMeetings.ready,
				reqMeetingRequests.ready,
				reqExternalCommitments.ready,
				reqThisUsersMeetings.ready,
				reqThisUsersMeetingRequests.ready
			]).then(
				([
					availabilityResponse,
					meetingsResponse,
					meetingRequestsResponse,
					externalCommitmentsResponse,
					thisUserMeetingsResponse,
					thisUsersMeetingRequestsResponse
				]): HighlightedTimeSlot[] => {
					// Convert bookings into events
					const meetingsResponseIds = meetingsResponse
						? meetingsResponse.page.map(item => item.id)
						: [];
					const meetings: HighlightedTimeSlot[] = [
						...(meetingsResponse ? meetingsResponse.page : []),
						...(thisUserMeetingsResponse
							? thisUserMeetingsResponse.page.filter(
									item => !meetingsResponseIds.includes(item.id)
							  )
							: [])
					].map((receivedEvent: any) => {
						function getStatus() {
							if (
								currentUserId !== undefined &&
								receivedEvent.uidGuest === currentUserId
							) {
								return receivedEvent.uidHost === mentorId
									? "userbooked"
									: "userbusy";
							} else {
								return "booked";
							}
						}

						return {
							id: receivedEvent.id,
							isBooking: true,
							start: moment.utc(receivedEvent.dateStartsUTC).toDate(),
							end: moment.utc(receivedEvent.dateEndsJustBeforeUTC).toDate(),
							status: getStatus()
						};
					});

					// Convert externally booked meetings to events
					const externallyBookedMeetings: HighlightedTimeSlot[] = (externalCommitmentsResponse
						? externalCommitmentsResponse.page
						: []
					)
						.filter(entry => entry.isExternallyBookedMeeting)
						.map((receivedEvent: ExternalCommitmentsData) => {
							return {
								id: receivedEvent.id,
								isBooking: true,
								start: moment.utc(receivedEvent.dateStartsUTC).toDate(),
								end: moment.utc(receivedEvent.dateEndsJustBeforeUTC).toDate(),
								status: "booked"
							};
						});

					// Convert pending bookings into events
					const meetingRequestsResponseIds = meetingRequestsResponse
						? meetingRequestsResponse.page.map(item => item.id)
						: [];
					const meetingRequests: HighlightedTimeSlot[] = [
						...(meetingRequestsResponse ? meetingRequestsResponse.page : []),
						...(thisUsersMeetingRequestsResponse
							? thisUsersMeetingRequestsResponse.page.filter(
									item => !meetingRequestsResponseIds.includes(item.id)
							  )
							: [])
					].map((receivedMeetingRequest: MeetingRequestsData) => {
						function getStatus() {
							if (receivedMeetingRequest.hostUid !== mentorId) {
								return "userbusy";
							}

							if (
								currentUserId ===
								(receivedMeetingRequest.hostUid ||
									receivedMeetingRequest.guestUid)
							) {
								return "awaitingmentorconfirmation";
							}

							return "booked";
						}

						return {
							id: receivedMeetingRequest.id,
							isBooking: true,
							start: moment.utc(receivedMeetingRequest.dateStartsUTC).toDate(),
							end: moment
								.utc(receivedMeetingRequest.dateEndsJustBeforeUTC)
								.toDate(),
							status: getStatus()
						};
					});

					const bookings = [
						...meetings,
						...meetingRequests,
						...externallyBookedMeetings
					];

					const allCurrentOrFutureTimeSlotsInCalendar = getTimeSlots(
						moment
							.max(
								moment(calendarStartDate),
								moment().add(
									...getCutOffPeriodForBookingAndConfirmingMeetings()
								)
							)
							.toDate(),
						calendarEndDate,
						slotDurationInMinutes
					);

					// Convert available but unbooked slots into events
					const allPotentialTimeSlots = flatten(
						availabilityResponse
							? availabilityResponse.page.map(
									({
										dayOfWeekLocaltime: dayOfWeekMentorsTimezone,
										timeFirstAvailableLocaltime: timeFirstAvailableMentorsTimezone,
										timeLastAvailableJustBeforeLocaltime: timeLastAvailableJustBeforeMentorsTimezone,
										timezone: mentorsTimezone
									}) => {
										const earliestPossibleTimeSlotForThisAvailabilityPeriod = new Date(
											startDateFromCalendarMs
										);

										const endOfLastPossibleTimeSlotForThisAvailabilityPeriod = calendarEndDate;

										const allDateRangesCorrespondingToThisAvailabilityPeriod = getAllDatesBetween(
											earliestPossibleTimeSlotForThisAvailabilityPeriod,
											endOfLastPossibleTimeSlotForThisAvailabilityPeriod
										)
											.filter(date => {
												return (
													moment
														.utc(date)
														.tz(mentorsTimezone)
														.isoWeekday() === dayOfWeekMentorsTimezone
												);
											})
											.map(date => ({
												startsAt: moment
													.utc(date)
													.tz(mentorsTimezone)
													.startOf("day")
													.add(timeFirstAvailableMentorsTimezone)
													.toDate(),
												endsBefore: moment
													.utc(date)
													.tz(mentorsTimezone)
													.startOf("day")
													.add(timeLastAvailableJustBeforeMentorsTimezone)
													.toDate()
											}));

										return allCurrentOrFutureTimeSlotsInCalendar.filter(
											timeSlot =>
												allDateRangesCorrespondingToThisAvailabilityPeriod.some(
													range =>
														moment(timeSlot.start).isSameOrAfter(
															range.startsAt
														) &&
														moment(timeSlot.end).isSameOrBefore(
															range.endsBefore
														)
												)
										);
									}
							  )
							: []
					);

					const uniqueTimeSlots: TimeSlot[] = deDup(
						allPotentialTimeSlots,
						slot => `${slot.start.getTime()}-${slot.end.getTime()}`
					);

					// Find commitments that are not externally booked meetings so that they can be removed
					// from the set of available slots
					const commitmentsOtherThanExternallyBookedMeetings: Omit<
						HighlightedTimeSlot,
						"status"
					>[] = (externalCommitmentsResponse
						? externalCommitmentsResponse.page
						: []
					)
						.filter(entry => !entry.isExternallyBookedMeeting)
						.map((receivedEvent: ExternalCommitmentsData) => {
							return {
								id: receivedEvent.id,
								isBooking: false,
								start: moment.utc(receivedEvent.dateStartsUTC).toDate(),
								end: moment.utc(receivedEvent.dateEndsJustBeforeUTC).toDate()
							};
						});

					const nonOverlappingSlots = uniqueTimeSlots.filter(slot => {
						if (bookings.some(booking => dateRangesOverlap(booking, slot))) {
							return false;
						}

						if (
							commitmentsOtherThanExternallyBookedMeetings.some(commitment =>
								dateRangesOverlap(commitment, slot)
							)
						) {
							return false;
						}

						if (
							uniqueTimeSlots.some(
								slotToCompare =>
									!isSameSlot(slotToCompare, slot) &&
									dateRangesOverlap(slotToCompare, slot)
							)
						) {
							return false;
						}

						return true;
					});

					const unbookedslots: HighlightedTimeSlot[] = nonOverlappingSlots.map(
						({ start, end }) => {
							return {
								id: `available-${start.getTime()}-${end.getTime()}`,
								isBooking: false,
								start,
								end,
								status: "available"
							};
						}
					);

					const output = [...bookings, ...unbookedslots];
					return output;
				}
			);

			return {
				ready,
				abort: () => {
					reqMeetings.abort();
					reqAvailability.abort();
				},
				aborted: () => {
					return reqMeetings.aborted() || reqAvailability.aborted();
				},
				type: "availability-calendar-combined"
			};
		},
		[
			mentorId,
			startDateFromCalendarMs,
			endDateFromCalendarMs,
			slotDurationInMinutes
		]
	);

	const [repeatNum, setRepeatNum] = useState(new Date().getTime());

	const [{ isSignedIn }] = useContext(AuthContext);

	// TODO:WV:20230401:It's not actually "waiting" as such if dontFetch is true, so would be better to handle that case explicitly
	const doWait =
		isSignedIn === undefined ||
		dontFetch ||
		!mentorId ||
		!startDateFromCalendarMs ||
		!endDateFromCalendarMs;
	const { isWaiting, isLoading, isError, output: events } = useRemoteResource<
		MeetingCalendarProps["events"]
	>(fetchEvents, doWait, repeatNum);

	const onNavigate = useCallback((n: number) => setWeekOffset(n), []);

	const calendarEvents = events ? events : [];

	const m = moment();
	const availabilityCalendarData: MeetingCalendarProps = {
		firstDateInFloatingTimezone: {
			year: m.year(),
			month: m.month(),
			date: m.date()
		},
		events: calendarEvents,
		onNavigate,
		onHaveNewDateRange: meetingCalendarHasNewDateRange,
		weekOffset,
		isSignedIn: !!isSignedIn,
		displaymode: isSmallScreen
			? ("compact" as "compact")
			: isMediumScreen
			? ("medium" as "medium")
			: ("full" as "full"),
		isLoading: isLoading || isWaiting,
		isError
	};

	return {
		availabilityCalendarData,
		update: () => setRepeatNum(new Date().getTime())
	};
}

function isSameSlot(slotA: TimeSlot, slotB: TimeSlot) {
	return (
		slotA.start.getTime() === slotB.start.getTime() &&
		slotA.end.getTime() === slotB.end.getTime()
	);
}

interface TimeSlot {
	start: Date;
	end: Date;
}

const getTimeSlots = (() => {
	const timeSlotsCache: { [k: string]: TimeSlot[] } = {};
	return function(
		startOfFirst: Date,
		endOfLast: Date,
		durationInMinutes: SlotDurationInMinutes
	): TimeSlot[] {
		const start = roundDateUpToNextHour(startOfFirst);
		const end = roundDateUpToNextHour(endOfLast);

		const cacheKey = `${start.getTime()}-${end.getTime()}`;
		if (timeSlotsCache[cacheKey]) {
			return timeSlotsCache[cacheKey];
		}

		const slots: TimeSlot[] = flatten(
			chunk(start, end, "hour")
				.map((slot: any) => {
					if (
						!(
							slot.start &&
							slot.start instanceof Date &&
							slot.end &&
							slot.end instanceof Date
						)
					) {
						throw new Error("Invalid slot");
					}
					return slot;
				})
				.map((slot: TimeSlot) => {
					switch (durationInMinutes) {
						case 60:
							return [slot];
						case 30:
							// Divide the slot into two half-hour slots
							const midPoint = moment(slot.start)
								.clone()
								.add(30, "minutes")
								.toDate();
							return [
								{
									start: slot.start,
									end: midPoint
								},
								{
									start: midPoint,
									end: slot.end
								}
							];
						default:
							throw new Error("Unknown slot duration");
					}
				})
		);

		timeSlotsCache[cacheKey] = slots;

		return timeSlotsCache[cacheKey];
	};
})();

interface HighlightedTimeSlot {
	id: string;
	isBooking: boolean;
	start: Date;
	end: Date;
	status:
		| "userbooked"
		| "booked"
		| "available"
		| "awaitingmentorconfirmation"
		| "userbusy";
}

function roundDateUpToNextHour(date: Date) {
	const mDate = moment(date);

	if (mDate.isSame(mDate.clone().startOf("hour"))) {
		return date;
	}

	return mDate
		.add(1, "hour")
		.startOf("hour")
		.toDate();
}
