import React, { useEffect, useState, useContext } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { WizardData } from "components/PageUserProfile/wizards/BuyPackageWizard/BuyPackageWizard";

import {
	flagValidationErrorsInPackagePrice,
	PackagePrice
} from "types-and-validators/flagValidationErrorsInPackagePrice";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import { OutputRow as PackagePricesData } from "api/getPackagePrices";
import { useAccountStatus } from "hooks/useAccountStatus";
import { formatPackagePrice } from "services/formatPackagePrice";
import { validate } from "validation/validate";
import terminology from "terminology.json";

import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import { FieldSelectContainer } from "./StepChoosePackagePrice.styles";

const defaultCountryCodeUntilAccountStatusLoads = "GB";

function StepChoosePackagePrice({
	setHaveSystemError,
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData: { mentorId, sessionType }
}: ModalWizardStepRenderProps<WizardData>) {
	const {
		isWaiting: sessionTypesAndPricesWaiting,
		isLoading: sessionTypesAndPricesLoading,
		isError: sessionTypesAndPricesError,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const packagePrices =
		sessionTypesAndPrices.packagePrices && sessionType
			? sessionTypesAndPrices.packagePrices.filter(
					packagePrice => packagePrice.sessionTypeId === sessionType.id
			  )
			: [];

	const [selectedPackagePrice, setSelectedPackagePrice] = useState<
		PackagePrice | undefined
	>();

	const [{ uid }] = useContext(AuthContext);

	useEffect(() => {
		if (sessionTypesAndPricesError) {
			setHaveSystemError(true);
		}
		if (selectedPackagePrice) {
			enableProgressToNextStep({ packagePrice: selectedPackagePrice });
		} else {
			disableProgressToNextStep();
		}
	}, [
		setHaveSystemError,
		enableProgressToNextStep,
		disableProgressToNextStep,
		selectedPackagePrice,
		sessionTypesAndPricesError
	]);

	const { locationCountryCode } = useAccountStatus();

	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(
		generateOptionsFromPackagePricesData(
			packagePrices,
			locationCountryCode
				? locationCountryCode
				: defaultCountryCodeUntilAccountStatusLoads
		)
	);
	const numCurrentOptions = currentOptions.length;
	const packagePricesStr = JSON.stringify(packagePrices);
	useEffect(() => {
		const prices = JSON.parse(packagePricesStr);
		if (numCurrentOptions === 0) {
			setCurrentOptions(
				generateOptionsFromPackagePricesData(
					prices,
					locationCountryCode
						? locationCountryCode
						: defaultCountryCodeUntilAccountStatusLoads
				)
			);
		}
	}, [
		packagePricesStr,
		numCurrentOptions,
		setCurrentOptions,
		locationCountryCode
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedPackagePrice: unknown =
			selectedOption && typeof selectedOption.value === "string"
				? JSON.parse(selectedOption.value)
				: undefined;

		if (
			selectedPackagePrice === undefined ||
			validate(selectedPackagePrice, {
				doValidate: flagValidationErrorsInPackagePrice,
				auth: { uid }
			})
		) {
			setSelectedPackagePrice(selectedPackagePrice);
		}
	}, [currentOptions, uid]);

	return (
		<FieldSelectContainer>
			<FieldSelect
				allowMultipleSelections={false}
				isLoading={sessionTypesAndPricesLoading || sessionTypesAndPricesWaiting}
				isError={sessionTypesAndPricesError}
				currentOptions={currentOptions}
				onNewCurrentOptions={setCurrentOptions}
			/>
		</FieldSelectContainer>
	);
}

export default StepChoosePackagePrice;

function generateOptionsFromPackagePricesData(
	data: PackagePricesData[],
	locationCountryCode: string
) {
	return data
		.sort((a, b) => a.price - b.price)
		.map(packagePrice => {
			const value = getValueFromPackagePrice(packagePrice);
			const jsonValue = JSON.stringify(value);
			return {
				id: jsonValue,

				// TODO:WV:20250305:Try to find a way to use value instead of jsonValue, which is currently being used to pacify typescript for the time being
				value: jsonValue,
				text:
					formatPackagePrice({
						packagePrice,
						locationCountryCode
					}) +
					(packagePrice.guestUid
						? ` - ${terminology.mentee}-specific price`
						: "")
			};
		});
}

function getValueFromPackagePrice({
	id,
	price,
	currency,
	sessionDurationMinutes,
	sessionTypeId,
	numMeetings
}: PackagePrice) {
	return {
		id,
		price,
		currency,
		sessionDurationMinutes,
		sessionTypeId,
		numMeetings
	};
}
