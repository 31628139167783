import { MentorProfileData, RawApiOutput } from "api/getUserProfile";
import { makeApiRequest, Auth } from "api/makeApiRequest";
import { SearchResultsWithCount } from "api/SearchResults";
import { ApiRequest } from "api/ApiRequest";
import { userProfileFromApiToLocal } from "api/userProfileFromApiToLocal";
import { captureMessage } from "services/captureMessage";
import { flagValidationErrors as flagValidationErrorsInProfile } from "api/getUserProfile";
import { ValidationCallback } from "validation/validate";
import { flagValidationErrorsInSearchResultsWithCount } from "validation/flagValidationErrorsInSearchResults";

type SetForMentorsParams = {
	cursor?: number;
	cursorType?: "after" | "before";
	location?: { locationDistrict: string; locationCountryCode: string };
	canhelpwith?: string[];
	specialistknowledge?: string[];
};

interface MentorSearchResult
	extends Omit<
		MentorProfileData,
		| "paymentProvidersMBWay"
		| "paymentProvidersWisetag"
		| "paymentProvidersCustom"
	> {
	sortindex: number;
}

const flagValidationErrors: ValidationCallback<
	SearchResultsWithCount<RawApiOutput<MentorProfileData>>
> = (input, utils, auth) =>
	flagValidationErrorsInSearchResultsWithCount(
		flagValidationErrorsInProfile,
		input,
		utils,
		auth
	);

export function searchForMentors(
	{
		cursor,
		cursorType,
		location,
		canhelpwith,
		specialistknowledge
	}: SetForMentorsParams = {},
	auth: Auth
): ApiRequest<SearchResultsWithCount<MentorSearchResult>> {
	const request = makeApiRequest("mentors", {
		auth,
		data: {
			get: {
				cursor: cursor ? cursor + "" : undefined,
				cursorType: cursorType ? cursorType + "" : undefined,
				...(location ? { location: JSON.stringify(location) } : {}),
				...(canhelpwith ? { canhelpwith: JSON.stringify(canhelpwith) } : {}),
				...(specialistknowledge
					? { specialistknowledge: JSON.stringify(specialistknowledge) }
					: {})
			}
		},
		flagValidationErrors
	});

	const ready: Promise<
		SearchResultsWithCount<MentorSearchResult>
	> = request.ready.then(results => {
		if (!results) {
			return {
				page: [],
				count: 0
			};
		}
		const { page, count, nextPage } = results;
		return {
			page: convertPage(page),
			count,
			...(nextPage ? { nextPage: convertPage(nextPage) } : {})
		};
	});

	return {
		ready,
		abort: () => request.abort(),
		aborted: () => request.aborted(),
		type: request.type
	};
}

export function convertPage(page: RawApiOutput<MentorProfileData>[]) {
	return page.map(profile => {
		const defaultSortIndex = 1;
		if (profile.sortindex === undefined) {
			captureMessage(
				`No sortindex in mentor profile - using '${defaultSortIndex}' instead.`,
				{ evtType: "mentorProfileNoSortIndex" }
			);
		}
		let sortindex: number =
			profile.sortindex === undefined ? defaultSortIndex : profile.sortindex;

		const convertedProfile = userProfileFromApiToLocal(profile);

		const {
			terms,
			hasLinkedZoomAccount,
			canAcceptPayments,
			hasSkypeId,
			mentorProfileAboutMe
		} = convertedProfile;

		const output: MentorSearchResult = {
			...convertedProfile,
			mentorProfileAboutMe: mentorProfileAboutMe ? mentorProfileAboutMe : "",
			terms: terms === undefined ? [] : terms,
			hasLinkedZoomAccount:
				hasLinkedZoomAccount === undefined ? false : hasLinkedZoomAccount,
			canAcceptPayments:
				canAcceptPayments === undefined ? false : canAcceptPayments,
			hasSkypeId: hasSkypeId === undefined ? false : hasSkypeId,
			sortindex
		};

		return output;
	});
}
