import React, { useCallback, useContext, useMemo } from "react";

import { SingleSessionPrice } from "types-and-validators/flagValidationErrorsInSingleSessionPrice";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import {
	MeetingCalendarEvent,
	validateMeetingCalendarEvent,
	SlotDurationInMinutes
} from "components/MeetingCalendar/MeetingCalendar";
import ModalWizard from "components/ModalWizard";
import { ModalWizardStep } from "components/ModalWizard/ModalWizard";
import StepChooseTimeSlot from "./StepChooseTimeSlot";
import StepChooseSessionType from "components/PageUserProfile/wizards/sharedSteps/StepChooseSessionType";
import StepChooseSessionDuration from "./StepChooseSessionDuration";
import StepChooseMedium from "./StepChooseMedium";
import StepLogin from "components/PageUserProfile/wizards/sharedSteps/StepLogin";
import StepAcceptTerms from "components/PageUserProfile/wizards/sharedSteps/StepAcceptTerms";
import StepConfirmAndAddMessage from "components/PageUserProfile/wizards/sharedSteps/StepConfirmAndAddMessage";
import { captureException } from "services/captureException";
import { sendMeetingRequest } from "api/sendMeetingRequest";
import { Medium } from "hooks/useAvailableMediums";
import { useHandleSubmit } from "components/PageUserProfile/wizards/sharedHooks/useHandleSubmit";
import { SessionType } from "types-and-validators/flagValidationErrorsInSessionType";
import { ucFirst } from "utils/ucFirst";
import {
	validate,
	ValidationCallback,
	validationErrorsToString
} from "validation/validate";
import terminology from "terminology.json";

const validateSessionType: ValidationCallback<SessionType> = (
	input,
	{ checkField }
) => {
	checkField("id", { type: "string" });
	checkField("sessionTitle", { type: "string" });
	checkField("sessionDescription", { type: "string" });
};

interface BookingWizardProps {
	mentorId: string;
	mentorDisplayName: string;
	isInProgress: boolean;
	selectedEvent?: MeetingCalendarEvent;
	currentUserUidWhenStarted?: string;
	hasAcceptedTermsWhenStarted?: boolean;
	onCancel: () => void;
	onSent: () => void;
	onFailed: () => void;
}

export interface SelectedDurationAndPrice
	extends Pick<
		SingleSessionPrice,
		"id" | "price" | "currency" | "sessionDurationMinutes"
	> {
	type: "cash" | "credit";
}

const validateSelectedDurationAndPrice: ValidationCallback<
	SelectedDurationAndPrice
> = (input, { checkField }) => {
	checkField("id", { type: "string" });
	checkField("price", { type: "number" });
	checkField("currency", { type: "string" });
	checkField("sessionDurationMinutes", { type: "number" });
	checkField("type", { type: "enum", allowedValues: ["cash", "credit"] });
};

export interface CompletedWizardData {
	mentorId: string;
	eventToBook: MeetingCalendarEvent;
	sessionType: SessionType;
	selectedDurationAndPrice: SelectedDurationAndPrice;
	medium: Medium;
	message: string;
	slotDurationInMinutes: SlotDurationInMinutes;
	wizardType: string;
}

const BookingWizard: React.FunctionComponent<BookingWizardProps> = ({
	mentorId,
	mentorDisplayName,
	isInProgress,
	selectedEvent,
	currentUserUidWhenStarted,
	hasAcceptedTermsWhenStarted,
	onCancel: onCancelWizard,
	onSent,
	onFailed
}) => {
	const handleSubmit = useHandleSubmit(
		terminology.meetingRequest,
		terminology.mentor,
		terminology.meeting,
		(data: CompletedWizardData, auth) => {
			const { eventToBook, message, selectedDurationAndPrice, medium } = data;

			if (!(auth && auth.uid)) {
				throw new Error("No requester UID");
			}

			return sendMeetingRequest(
				{
					hostUid: mentorId,
					guestUid: auth.uid,
					message,
					paymentTokenId: selectedDurationAndPrice.id,
					paymentTokenType:
						selectedDurationAndPrice.type === "cash"
							? "singleSessionPrice"
							: "credit",
					medium,
					sessionEvent: eventToBook
				},
				auth
			);
		},
		onSent,
		onFailed
	);

	const steps = useMemo<ModalWizardStep<CompletedWizardData>[]>(() => {
		const steps: (ModalWizardStep<CompletedWizardData> | null)[] = [
			{
				key: "time-slot",
				title: "Choose time slot",
				component: StepChooseTimeSlot,
				skipThisStep: wizardData => wizardData.eventToBook !== undefined
			},
			{
				key: "session-type",
				title: "Choose session type",
				component: StepChooseSessionType
			},
			{
				key: "session-price",
				title: "Choose session duration",
				component: StepChooseSessionDuration
			},
			{
				key: "medium",
				title: "Choose medium",
				component: StepChooseMedium
			},
			currentUserUidWhenStarted
				? null
				: {
						key: "login-or-register",
						title: "Login or register",
						component: StepLogin,
						nextButtonText: () => "Login / register"
				  },
			hasAcceptedTermsWhenStarted
				? null
				: {
						key: "accept-terms",
						title: "Accept terms",
						component: StepAcceptTerms,
						nextButtonText: () => "I accept"
				  },
			{
				key: "message",
				title: "Confirm and add message",
				component: StepConfirmAndAddMessage,
				nextButtonText: (
					wizardData: Partial<CompletedWizardData>,
					steps: ModalWizardStep<CompletedWizardData>[],
					thisStepKey: string
				) => {
					const thisStepIndex = steps.findIndex(
						step => step.key === thisStepKey
					);
					if (thisStepIndex === -1) {
						captureException(new Error("Step not found"), {
							evtType: "stepNotFound",
							extra: { steps, thisStepKey }
						});
						return "Next";
					}

					const subsequentVisibleSteps = steps
						.slice(thisStepIndex + 1)
						.filter(step =>
							step.skipThisStep
								? !step.skipThisStep(wizardData, steps, step.key)
								: true
						);

					if (subsequentVisibleSteps.length === 0) {
						return "Confirm and request booking";
					}

					return "Next";
				}
			}
		];

		return steps.filter(
			(step): step is ModalWizardStep<CompletedWizardData> => step !== null
		);
	}, [currentUserUidWhenStarted, hasAcceptedTermsWhenStarted]);

	const [{ uid }] = useContext(AuthContext);
	const validateCompletedData: ValidationCallback<
		CompletedWizardData
	> = useCallback(
		(input, { flag, checkField }) => {
			const {
				mentorId,
				eventToBook,
				sessionType,
				selectedDurationAndPrice,
				medium
			} = input;

			checkField("message", { type: "string", isRequired: false });

			// TODO:WV:20240303:Better feedback
			if (!!uid && mentorId === uid) {
				flag("mentorId", "you cannot book a meeting with yourself");
			}

			if (!eventToBook) {
				flag("eventToBook", "missing");
			} else {
				validate(eventToBook, {
					auth: { uid },
					doValidate: validateMeetingCalendarEvent,
					withErrors: eventErrors => {
						flag("eventToBook", validationErrorsToString(eventErrors));
					}
				});
			}

			if (!sessionType) {
				flag("sessionType", "missing");
			} else {
				validate(sessionType, {
					auth: { uid },
					doValidate: validateSessionType,
					withErrors: sessionTypeErrors => {
						flag("sessionType", validationErrorsToString(sessionTypeErrors));
					}
				});
			}

			if (!selectedDurationAndPrice) {
				flag("selectedDurationAndPrice", "missing");
			} else {
				validate(selectedDurationAndPrice, {
					auth: { uid },
					doValidate: validateSelectedDurationAndPrice,
					withErrors: errors => {
						flag("selectedDurationAndPrice", validationErrorsToString(errors));
					}
				});
			}

			if (!medium) {
				flag("medium", "missing");
			} else if (typeof medium !== "string") {
				flag("medium", "not a string");
			} else {
				if (!["google-meet", "zoom", "skype"].includes(medium)) {
					flag("medium", "invalid value");
				}
			}
		},
		[uid]
	);

	if (!(mentorId && isInProgress)) {
		return null;
	}

	return (
		<ModalWizard<CompletedWizardData>
			isInProgress={!!isInProgress}
			cancelButtonText="Cancel booking"
			onCancel={onCancelWizard}
			onComplete={handleSubmit}
			description={`${ucFirst(terminology.meeting)}${
				mentorDisplayName ? ` with ${mentorDisplayName}` : ``
			}`}
			validateCompletedData={validateCompletedData}
			initialData={{
				mentorId,
				eventToBook: selectedEvent,
				wizardType: "book-lesson"
			}}
			steps={steps}
		/>
	);
};

export default BookingWizard;
