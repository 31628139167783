import { useEffect, useContext } from "react";
import queryString from "query-string";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";

import { StartWizardParams } from "components/PageUserProfile/wizards/sharedHooks/useStartWizard";

type SetBookingWizardInProgress = (
	value: React.SetStateAction<StartWizardParams<MeetingCalendarEvent>>
) => void;

export const useShowBookingInterface = (
	setBookingWizardInProgress: SetBookingWizardInProgress
) => {
	const [{ isSignedIn, uid, hasAcceptedTerms }] = useContext(AuthContext);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useEffect(() => {
		const { showBookingInterface } = queryString.parse(window.location.search);
		if (showBookingInterface && isSignedIn) {
			stripQueryStringFromAddressBar();
			setBookingWizardInProgress({
				isInProgress: true,
				currentUserUid: uid,
				hasAcceptedTerms
			});
		}
	}, [
		setBookingWizardInProgress,
		isSignedIn,
		stripQueryStringFromAddressBar,
		uid,
		hasAcceptedTerms
	]);
};
