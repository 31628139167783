import moment from "moment-timezone";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import {
	flagValidationErrorsInSessionType,
	SessionType
} from "types-and-validators/flagValidationErrorsInSessionType";
import {
	flagValidationErrorsInPackagePrice,
	PackagePrice
} from "types-and-validators/flagValidationErrorsInPackagePrice";

import { ApiRequest } from "api/ApiRequest";
import {
	ValidationCallback,
	validate,
	validationErrorsToString
} from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";

interface RawApiOutputRow {
	id: string;
	hostUid: string;
	guestUid: string;
	privateMessageContents?: string;
	privateMessageDatesentUTC?: string;
	privateMessageId?: string;
	isAccepted: boolean;
	isRejected: boolean;
	displayNameGuest: string;
	displayNameHost: string;
	packagePrice: PackagePrice;
	sessionType: SessionType;
}

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ flag, checkField },
	{ uid }
) => {
	const {
		privateMessageDatesentUTC,
		privateMessageId,
		isAccepted,
		isRejected,
		displayNameGuest,
		displayNameHost
	} = input;

	checkField("id", { type: "string" });
	checkField("hostUid", { type: "string" });
	checkField("guestUid", { type: "string" });
	checkField("privateMessageContents", { type: "string", isRequired: false });

	if (
		privateMessageDatesentUTC &&
		!(typeof privateMessageDatesentUTC === "string")
	) {
		flag("privateMessageDatesentUTC", "invalid");
	}

	if (privateMessageId && !(typeof privateMessageId === "string")) {
		flag("privateMessageId", "invalid");
	}

	if (isAccepted === undefined) {
		flag("isAccepted", "missing");
	} else if (!(typeof isAccepted === "boolean")) {
		flag("isAccepted", "invalid");
	}

	if (isRejected === undefined) {
		flag("isRejected", "missing");
	} else if (!(typeof isRejected === "boolean")) {
		flag("isRejected", "invalid");
	}

	if (displayNameGuest === undefined) {
		flag("displayNameGuest", "missing");
	} else if (!(typeof displayNameGuest === "string")) {
		flag("displayNameGuest", "invalid");
	}

	if (displayNameHost === undefined) {
		flag("displayNameHost", "missing");
	} else if (!(typeof displayNameHost === "string")) {
		flag("displayNameHost", "invalid");
	}

	validate(input.sessionType, {
		doValidate: flagValidationErrorsInSessionType,
		withErrors: errors => {
			flag("sessionType", validationErrorsToString(errors));
		},
		auth: { uid }
	});

	validate(input.packagePrice, {
		doValidate: flagValidationErrorsInPackagePrice,
		withErrors: errors => {
			flag("packagePrice", validationErrorsToString(errors));
		},
		auth: { uid }
	});
};

const flagValidationErrors: ValidationCallback<
	SearchResults<RawApiOutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export interface OutputRow
	extends Omit<RawApiOutputRow, "privateMessageDatesentUTC"> {
	privateMessageDatesentUTC?: Date;
}

interface Params {
	hostUid?: string;
	guestUid?: string;
	isAccepted?: boolean;
	isRejected?: boolean;

	cacheInMemory?: boolean;
}

export function getPackageRequests(
	{ hostUid, guestUid, cacheInMemory, isAccepted = true, isRejected }: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("packageRequests", {
		auth,
		cacheInMemory,
		data: {
			get: {
				hostUid,
				guestUid,
				...(isAccepted !== undefined
					? { isAccepted: isAccepted ? "1" : "0" }
					: {}),
				...(isRejected !== undefined
					? { isRejected: isRejected ? "1" : "0" }
					: {})
			}
		},
		flagValidationErrors
	});

	return {
		ready: req.ready.then(response => {
			return {
				page: response
					? response.page.map(row => {
							const {
								privateMessageDatesentUTC,
								isAccepted,
								isRejected,
								...rest
							} = row;
							return {
								...rest,
								...(privateMessageDatesentUTC
									? {
											privateMessageDatesentUTC: moment
												.utc(privateMessageDatesentUTC)
												.toDate()
									  }
									: {}),
								isAccepted: !!isAccepted,
								isRejected: !!isRejected
							};
					  })
					: []
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
