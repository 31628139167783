import { getTimezoneOffsetFromLocalTimezone } from "utils/datesAndTimes/getTimezoneOffsetFromLocalTimezone";

/* This function returns a new date object based on the date passed in, such that
   when displayed in the user's local timezone, the time given is the same as the
   original passed in date displayed in the targetTimezone.  It is necessary because
   the version of FullCalendar in use in this project insists on using the user's
   local timezone, whereas we want to use the value stored in their "timezone"
   account config option, if possible. */
export function offsetByTimezone(
	referenceDateTime: Date,
	targetTimezone: string
) {
	return new Date(
		referenceDateTime.getTime() +
			60000 *
				getTimezoneOffsetFromLocalTimezone(referenceDateTime, targetTimezone)
	);
}
