import React, { useState, useEffect, useContext } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import SelectedEventSummary from "components/SelectedEventSummary";
import { OutputRow as SessionTypesData } from "api/getSessionTypes";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useCredits } from "hooks/useCredits";
import { formatSessionType } from "services/formatSessionType";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import { useDefaultTimezone } from "hooks/useDefaultTimezone";
import { FieldSelectContainer } from "./StepChooseSessionType.styles";
import {
	SessionType,
	flagValidationErrorsInSessionType
} from "types-and-validators/flagValidationErrorsInSessionType";
import { validate } from "validation/validate";

const defaultCountryCodeUntilAccountStatusLoads = "GB";

type CompletedWizardData = {
	mentorId: string;
	eventToBook: MeetingCalendarEvent;
	sessionType: SessionType;
	wizardType: string;
};

function StepChooseSessionType({
	enableProgressToNextStep,
	disableProgressToNextStep,
	setHaveSystemError,
	wizardData: { mentorId, eventToBook, wizardType }
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const {
		isWaiting: sessionTypesAndPricesWaiting,
		isLoading: sessionTypesAndPricesLoading,
		isError: sessionTypesAndPricesError,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const [selectedSessionType, setSessionType] = useState<
		SessionType | undefined
	>();

	useEffect(() => {
		if (sessionTypesAndPricesError) {
			setHaveSystemError(true);
		} else if (selectedSessionType) {
			enableProgressToNextStep({ sessionType: selectedSessionType });
		} else {
			disableProgressToNextStep();
		}
	}, [
		setHaveSystemError,
		enableProgressToNextStep,
		disableProgressToNextStep,
		selectedSessionType,
		sessionTypesAndPricesError
	]);

	const defaultTimezone = useDefaultTimezone();

	const { locationCountryCode } = useAccountStatus();

	const [{ uid }] = useContext(AuthContext);

	const sessionTypesWithPackages = sessionTypesAndPrices.sessionTypes.filter(
		sessionType =>
			sessionTypesAndPrices.packagePrices.some(
				packagePrice => packagePrice.sessionTypeId === sessionType.id
			)
	);

	const sessionTypesThatTheCurrentUserHasActiveCreditsFor = sessionTypesAndPrices.packagePrices
		.filter(
			/* Find one package price for each session ID
			  (it is only necessary to know that the user
			  has at least one credit for a session, to show
			  it in this list if it is not available for
			  general sale) */
			(value, index, self) =>
				index ===
				self.findIndex(element => element.sessionTypeId === value.sessionTypeId)
		)
		.map(({ sessionTypeId }) => ({
			sessionTypeId,
			spendingStatus: "available" as "available"
		}));

	const {
		isWaiting: creditsWaiting,
		isLoading: creditsLoading,
		isError: creditsError,
		output: credits
	} = useCredits({
		uid,
		creditTypes: sessionTypesThatTheCurrentUserHasActiveCreditsFor
	});

	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(
		generateOptionsFromSessionTypesData(
			wizardType === "book-lesson"
				? getAvailableSessionTypes(sessionTypesAndPrices, credits)
				: wizardType === "buy-package"
				? sessionTypesWithPackages
				: [],
			locationCountryCode
				? locationCountryCode
				: defaultCountryCodeUntilAccountStatusLoads
		)
	);
	const numCurrentOptions = currentOptions.length;

	// NB Must wait until sessionTypesAndPrices finishes waiting and loading,
	// because it is generated via several API requests, and if they are not
	// all complete, only the first will be used.  By similar reasoning it
	// is also necessary to wait for credits to arrive from the API

	const availableSessionTypes =
		wizardType === "book-lesson"
			? sessionTypesAndPricesWaiting ||
			  sessionTypesAndPricesLoading ||
			  creditsWaiting ||
			  creditsLoading
				? []
				: getAvailableSessionTypes(sessionTypesAndPrices, credits)
			: wizardType === "buy-package"
			? sessionTypesWithPackages
			: [];

	const availableSessionTypesStr = JSON.stringify(availableSessionTypes);
	useEffect(() => {
		const sessionTypes = JSON.parse(availableSessionTypesStr);
		if (sessionTypes && numCurrentOptions === 0) {
			setCurrentOptions(
				generateOptionsFromSessionTypesData(
					sessionTypes,
					locationCountryCode
						? locationCountryCode
						: defaultCountryCodeUntilAccountStatusLoads
				)
			);
		}
	}, [
		availableSessionTypesStr,
		numCurrentOptions,
		setCurrentOptions,
		locationCountryCode
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedSessionType: unknown =
			selectedOption &&
			selectedOption.value !== null &&
			typeof selectedOption.value === "string"
				? JSON.parse(selectedOption.value)
				: undefined;

		if (
			selectedSessionType !== undefined &&
			validate(selectedSessionType, {
				doValidate: flagValidationErrorsInSessionType,
				auth: { uid }
			})
		) {
			setSessionType(selectedSessionType);
		}
	}, [
		currentOptions,
		enableProgressToNextStep,
		disableProgressToNextStep,
		uid
	]);

	if (!wizardType) {
		throw new Error("No wizard type found");
	}

	if (!["book-lesson", "buy-package"].includes(wizardType)) {
		throw new Error("Invalid wizard type");
	}

	return (
		<>
			{eventToBook ? (
				<>
					{/* Don't show end-time because it is currently one hour after the start-time (but it should depend on what the user chooses in this step) */}
					<SelectedEventSummary
						eventToBook={eventToBook}
						showEndTime={false}
						timezone={defaultTimezone}
					/>
				</>
			) : null}
			<FieldSelectContainer>
				<FieldSelect
					allowMultipleSelections={false}
					isLoading={
						sessionTypesAndPricesLoading || sessionTypesAndPricesWaiting
					}
					isError={sessionTypesAndPricesError || creditsError}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
				/>
			</FieldSelectContainer>
		</>
	);
}

export default StepChooseSessionType;

// TODO:WV:20220118:Test this
function getAvailableSessionTypes(
	sessionTypesAndPrices: ReturnType<typeof useSessionTypesAndPrices>["output"],
	credits: ReturnType<typeof useCredits>["output"]
) {
	return sessionTypesAndPrices.sessionTypes.filter(sessionType => {
		const haveSingleSessionPrice = sessionTypesAndPrices.singleSessionPrices.some(
			singleSessionPrice => singleSessionPrice.sessionTypeId === sessionType.id
		);
		const haveCredit = credits
			? credits.some(
					output =>
						output &&
						output.page.some(credit => credit.sessionTypeId === sessionType.id)
			  )
			: false;
		return haveSingleSessionPrice || haveCredit;
	});
}

function generateOptionsFromSessionTypesData(
	data: SessionTypesData[],
	locationCountryCode: string
) {
	return data
		.sort((a, b) => a.sessionTitle.localeCompare(b.sessionTitle))
		.map(sessionType => {
			const value = getValueFromSessionType(sessionType);
			const jsonValue = JSON.stringify(value);

			return {
				id: jsonValue,

				// TODO:WV:20250305:Try to move away from jsonValue (which is just to pacify typescript) back to "value"
				value: jsonValue,
				text: formatSessionType({ sessionType })
			};
		});
}

function getValueFromSessionType({
	id,
	sessionTitle,
	sessionDescription
}: SessionType) {
	return {
		id,
		sessionTitle,
		sessionDescription
	};
}
