import { useState, useCallback } from "react";

import terminology from "terminology.json";

import {
	useStartWizard,
	StartWizardParams
} from "components/PageUserProfile/wizards/sharedHooks/useStartWizard";

export const useBuyPackageWizard = (vanityUrl: string, uid: string) => {
	const [buyPackageWizardInProgress, setBuyPackageWizardInProgress] = useState<
		StartWizardParams<React.MouseEvent<HTMLButtonElement, MouseEvent>>
	>({ isInProgress: false });

	const startBuyPackageWizard = useStartWizard(
		terminology.package,
		setBuyPackageWizardInProgress,
		vanityUrl,
		uid
	);

	const handleEndBuyPackageWizard = useCallback(() => {
		setBuyPackageWizardInProgress({ isInProgress: false });
	}, [setBuyPackageWizardInProgress]);

	return {
		buyPackageWizardInProgress,
		setBuyPackageWizardInProgress,
		startBuyPackageWizard,
		handleEndBuyPackageWizard
	};
};
