import React, { useCallback, useContext, useMemo } from "react";

import {
	flagValidationErrorsInPackagePrice,
	PackagePrice
} from "types-and-validators/flagValidationErrorsInPackagePrice";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import ModalWizard from "components/ModalWizard";
import { ModalWizardStep } from "components/ModalWizard/ModalWizard";
import StepChooseSessionType from "components/PageUserProfile/wizards/sharedSteps/StepChooseSessionType";
import StepChoosePackagePrice from "components/PageUserProfile/wizards/BuyPackageWizard/StepChoosePackagePrice";
import StepLogin from "components/PageUserProfile/wizards/sharedSteps/StepLogin";
import StepAcceptTerms from "components/PageUserProfile/wizards/sharedSteps/StepAcceptTerms";
import StepConfirmAndAddMessage from "components/PageUserProfile/wizards/sharedSteps/StepConfirmAndAddMessage";
import { captureException } from "services/captureException";
import { sendPackageRequest } from "api/sendPackageRequest";
import { useHandleSubmit } from "components/PageUserProfile/wizards/sharedHooks/useHandleSubmit";
import { ucFirst } from "utils/ucFirst";
import {
	validate,
	ValidationCallback,
	validationErrorsToString
} from "validation/validate";
import terminology from "terminology.json";
import { SessionType } from "types-and-validators/flagValidationErrorsInSessionType";

interface BuyPackageWizardProps {
	mentorId: string;
	mentorDisplayName: string;
	isInProgress: boolean;
	currentUserUidWhenStarted?: string;
	hasAcceptedTermsWhenStarted?: boolean;
	onCancel: () => void;
	onSent: () => void;
	onFailed: () => void;
}

export interface CompletedWizardData {
	mentorId: string;
	sessionType: SessionType;
	packagePrice: PackagePrice;
	message: string;
	wizardType: string;
}

export type WizardData = Partial<CompletedWizardData>;

const BuyPackageWizard: React.FunctionComponent<BuyPackageWizardProps> = ({
	mentorId,
	mentorDisplayName,
	isInProgress,
	currentUserUidWhenStarted,
	hasAcceptedTermsWhenStarted,
	onCancel: onCancelWizard,
	onSent,
	onFailed
}) => {
	const handleSubmit = useHandleSubmit(
		terminology.packageRequest,
		terminology.mentor,
		terminology.package,
		(data: CompletedWizardData, auth) => {
			const { packagePrice, message } = data;

			if (!(auth && auth.uid)) {
				throw new Error("No requester UID");
			}

			return sendPackageRequest(
				{
					hostUid: mentorId,
					guestUid: auth.uid,
					message,
					packagePriceId: packagePrice.id
				},
				auth
			);
		},
		onSent,
		onFailed
	);

	const steps = useMemo<ModalWizardStep<CompletedWizardData>[]>(() => {
		const steps: (ModalWizardStep<CompletedWizardData> | null)[] = [
			{
				key: "session-type",
				title: "Choose session type",
				component: StepChooseSessionType
			},
			{
				key: "package-price",
				title: "Choose session duration and quantity",
				component: StepChoosePackagePrice
			},
			currentUserUidWhenStarted
				? null
				: {
						key: "login-or-register",
						title: "Login or register",
						component: StepLogin,
						nextButtonText: () => "Login / register"
				  },
			hasAcceptedTermsWhenStarted
				? null
				: {
						key: "accept-terms",
						title: "Terms and conditions",
						component: StepAcceptTerms,
						nextButtonText: () => "I accept"
				  },
			{
				key: "message",
				title: "Confirm and add message",
				component: StepConfirmAndAddMessage,
				nextButtonText: (
					wizardData: WizardData,
					steps: ModalWizardStep<WizardData>[],
					thisStepKey: string
				) => {
					const thisStepIndex = steps.findIndex(
						step => step.key === thisStepKey
					);
					if (thisStepIndex === -1) {
						captureException(new Error("Step not found"), {
							evtType: "stepNotFound",
							extra: { steps, thisStepKey }
						});
						return "Next";
					}

					const subsequentVisibleSteps = steps
						.slice(thisStepIndex + 1)
						.filter(step =>
							step.skipThisStep
								? !step.skipThisStep(wizardData, steps, step.key)
								: true
						);

					if (subsequentVisibleSteps.length === 0) {
						return "Confirm and request package";
					}

					return "Next";
				}
			}
		];
		return steps.filter(
			(step): step is ModalWizardStep<CompletedWizardData> => step !== null
		);
	}, [currentUserUidWhenStarted, hasAcceptedTermsWhenStarted]);

	const [{ uid }] = useContext(AuthContext);
	const validateCompletedData: ValidationCallback<WizardData> = useCallback(
		(input, { flag, checkField }) => {
			const { packagePrice, mentorId } = input;

			checkField("message", { type: "string", isRequired: false });

			// TODO:WV:20240303:Better feedback
			if (!!uid && mentorId === uid) {
				flag("mentorId", "you cannot buy your own packages");
			}

			if (!packagePrice) {
				flag("packagePrice", "missing");
			} else {
				validate(packagePrice, {
					auth: { uid },
					doValidate: flagValidationErrorsInPackagePrice,
					withErrors: packagePriceErrors => {
						flag("packagePrice", validationErrorsToString(packagePriceErrors));
					}
				});
			}
		},
		[uid]
	);

	if (!mentorId && isInProgress) {
		return null;
	}

	return (
		<ModalWizard<CompletedWizardData>
			isInProgress={!!isInProgress}
			cancelButtonText="Cancel purchase"
			onCancel={onCancelWizard}
			onComplete={handleSubmit}
			description={`${ucFirst(terminology.package)}${
				mentorDisplayName ? ` with ${mentorDisplayName}` : ``
			}`}
			validateCompletedData={validateCompletedData}
			steps={steps}
			initialData={{ mentorId, wizardType: "buy-package" }}
		/>
	);
};

export default BuyPackageWizard;
