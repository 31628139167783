import React, { useState, useCallback } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import ActionButtons from "components/ActionButtons";
import ModalFieldText from "components/ModalFieldText";
import { useAccountStatus } from "hooks/useAccountStatus";
import { StripeAccountOnboardingStatus } from "services/stripe/isValidStripeAccountOnboardingStatus";
import StripeLinker from "components/StripeLinker";
import KeyValueTable from "components/KeyValueTable";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import ModalPleaseWait from "components/ModalPleaseWait";
import { captureMessage } from "services/captureMessage";
import terminology from "terminology.json";

import {
	PaymentMethodsWrapper,
	ActionButtonsWrapper
} from "./ReceivingMoneySection.styles";

const ReceivingMoneySection: React.FunctionComponent = () => {
	const {
		stripeAccountOnboardingStatus,
		paymentProvidersMBWay,
		paymentProvidersWisetag,
		paymentProvidersCustom
	} = useAccountStatus();

	const [isSaving, setIsSaving] = useState(false);

	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"saving your settings",
		setIsSaving,
		async () => {}
	);

	const getStripeDescription = useCallback(
		(stripeAccountOnboardingStatus?: StripeAccountOnboardingStatus) => {
			if (stripeAccountOnboardingStatus === undefined) {
				return "loading...";
			}

			switch (stripeAccountOnboardingStatus) {
				case "unlinked":
					return "(not configured)";
				case "linked":
					return "Ready to accept payments";
				case "account-requested":
					return "Application pending";
				default:
					captureMessage("Unknown stripe status", {
						evtType: "unknownStripeStatus",
						extra: {
							stripeAccountOnboardingStatus
						}
					});
					return "Unknown status";
			}
		},
		[]
	);

	const [isEditing, setIsEditing] = useState(false);

	const rgxTelephoneNumber = /^\s*\+?[0-9]+\s*$/;

	const handleOKMBWay = useCallback(
		(value: string) => {
			submitDataToAPI({ data: { paymentProvidersMBWay: value.trim() } });
		},
		[submitDataToAPI]
	);
	const handleOKWisetag = useCallback(
		(value: string) => {
			submitDataToAPI({ data: { paymentProvidersWisetag: value.trim() } });
		},
		[submitDataToAPI]
	);
	const handleOKCustom = useCallback(
		(value: string) => {
			submitDataToAPI({ data: { paymentProvidersCustom: value.trim() } });
		},
		[submitDataToAPI]
	);

	return (
		<>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />

			<h2>Payment methods</h2>

			<PaymentMethodsWrapper>
				<KeyValueTable
					supportsLongEntries
					data={[
						{
							row: [
								"Card",
								getStripeDescription(stripeAccountOnboardingStatus)
							],
							key: "card"
						},
						{
							row: [
								"MBWay",
								<ModalFieldText
									isUnlocked={isEditing}
									title="How to pay you by MBWay"
									helptext={`If you have an MBWay account, you can enter your MBWay number below and it will be presented to ${terminology.mentees} after booking as a payment option.  Note: take care regarding privacy as your MBWay number is often your personal phone number.`}
									onOK={handleOKMBWay}
									value={paymentProvidersMBWay ? paymentProvidersMBWay : ""}
									validate={value => rgxTelephoneNumber.test(value)}
								/>
							],
							key: "mbway"
						},
						{
							row: [
								"Wise (Wisetag)",
								<ModalFieldText
									isUnlocked={isEditing}
									title="How to pay you by Wise using your Wisetag"
									helptext={`You should be able to find your Wisetag in the Wise app (it might start with an @ sign, e.g. @yourname123).  If you enter it here, it will be presented to ${terminology.mentees} as a payment option after booking.`}
									onOK={handleOKWisetag}
									value={paymentProvidersWisetag ? paymentProvidersWisetag : ""}
								/>
							],
							key: "wise-email"
						},
						{
							row: [
								"Custom",
								<ModalFieldText
									isUnlocked={isEditing}
									title="Any custom payment instructions"
									helptext={`If you want to be able to receive money by any other payment method, please add brief instructions for your ${terminology.mentees} here.  They will be presented as a payment option after booking.`}
									onOK={handleOKCustom}
									value={paymentProvidersCustom ? paymentProvidersCustom : ""}
								/>
							],
							key: "custom"
						}
					]}
				/>
			</PaymentMethodsWrapper>

			<ActionButtonsWrapper>
				<ActionButtons
					secondary={{
						onClick: () => setIsEditing(isEditing ? false : true),
						icon: faPen,
						label: isEditing ? "Done" : "Edit"
					}}
				/>
			</ActionButtonsWrapper>

			<h2>Online card payments via Stripe</h2>
			<StripeLinker />
		</>
	);
};

export default ReceivingMoneySection;
