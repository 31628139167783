import React from "react";

import FieldText from "components/FieldText";
import { Props as FieldTextProps } from "components/FieldText/FieldText";

type Props = Pick<
	FieldTextProps,
	"value" | "onChange" | "highlightValidationError"
> & {
	type?: "text" | "number";
	text: string;
};

const OtherField: React.FunctionComponent<Props> = ({
	type,
	text,
	value,
	onChange,
	highlightValidationError
}) => {
	const subType = type ? type : "text";
	return (
		<FieldText
			placeholder={`${text}${subType === "number" ? ` (enter a number)` : ``}`}
			value={value}
			onChange={onChange}
			subType={subType}
			highlightValidationError={highlightValidationError}
		/>
	);
};

export default OtherField;
