import React, { useState, useMemo } from "react";

import Modal from "components/Modal";
import Step from "components/ModalWizard/Step/Step";
import { useModalStyles } from "hooks/useModalStyles";
import { ValidationCallback } from "validation/validate";

import { useModalWizard } from "./useModalWizard";

export interface OnCompleteOutput {
	ready: Promise<void>;
	abort: () => void;
	aborted: () => boolean;
}

type ModalWizardProps<CompletedWizardData> = {
	isInProgress: boolean;
	onCancel: () => void;
	steps: ModalWizardStep<CompletedWizardData>[];
	onComplete: (wizardData: CompletedWizardData) => OnCompleteOutput;
	validateCompletedData: ValidationCallback<CompletedWizardData>;
	cancelButtonText?: string;
	description?: string;
	initialData: Partial<CompletedWizardData>;
};

export type NextButtonTextFunction<CompletedWizardData> = (
	wizardData: Partial<CompletedWizardData>,
	steps: ModalWizardStep<Partial<CompletedWizardData>>[],
	thisStepKey: string
) => string | null;

export type ModalWizardStep<CompletedWizardData> = {
	key: string;
	title: string;
	shortTitle?: string;
	nextButtonText?: NextButtonTextFunction<Partial<CompletedWizardData>>;
	skipThisStep?: (
		wizardData: Partial<CompletedWizardData>,
		steps: ModalWizardStep<Partial<CompletedWizardData>>[],
		thisStepKey: string
	) => boolean;
	doScrollToInitialPosition?: boolean;
	component: React.FunctionComponent<
		ModalWizardStepRenderProps<Partial<CompletedWizardData>>
	>;
};

export type ModalWizardStepRenderProps<CompletedWizardData> = {
	enableProgressToNextStep: (newData: Partial<CompletedWizardData>) => void;
	disableProgressToNextStep: (arg?: {
		alert?: { title: string; contents: string };
	}) => void;
	progressToNextStepNow: (newData: Partial<CompletedWizardData>) => void;
	setHaveSystemError: (arg: boolean) => void;
	wizardData: Partial<CompletedWizardData>;

	// TODO:WV:20240316:Add setOnClickCancel as well.
	setOnClickNext: React.Dispatch<
		React.SetStateAction<(() => boolean | undefined | void) | undefined>
	>;
};

function ModalWizard<CompletedWizardData>({
	isInProgress,
	onCancel,
	steps,
	initialData,
	onComplete,
	validateCompletedData,
	cancelButtonText,
	description
}: ModalWizardProps<CompletedWizardData>) {
	const {
		setHaveSystemError,
		handleMoveToNextStep,
		disableProgressToNextStep,
		enableProgressToNextStep,
		wizardData,
		currentStep,
		nextButtonText,
		haveSystemError,
		onOK,
		cancelWizard,
		handleCloseModal,
		stepNumber,
		totalNumSteps
	} = useModalWizard<CompletedWizardData>({
		steps,
		initialData,
		onCancel,
		validateCompletedData,
		onComplete
	});

	const {
		key,
		title,
		doScrollToInitialPosition,
		component: Component
	} = currentStep;

	const modalStyles = useModalStyles("medium");

	const [onClickNext, setOnClickNext] = useState<
		() => boolean | undefined | void
	>();

	// Reset 'next' button behaviour between steps
	// Use useMemo rather than useEffect because it runs the callback before child components
	// render rather than after they do, which is crucial to prevent this line from overwriting
	// any onClickNext callbacks set by the children.
	// TODO:WV:20240416:Find a less hacky way of doing it, e.g. by storing onClick callbacks in a lookup based on the step 'key'
	useMemo(() => {
		// Suppress warning about useMemo having an unnecessary dependency
		// (the 'key' dependency is in fact necessary so that this callback
		// function only runs between steps)
		// eslint-disable-next-line
		const dummy = key;

		setOnClickNext(undefined);
	}, [key, setOnClickNext]);

	return (
		<Modal
			isOpen={isInProgress}
			style={modalStyles}
			onRequestClose={handleCloseModal}
		>
			<Step
				isError={haveSystemError}
				doScrollToInitialPosition={doScrollToInitialPosition}
				title={title}
				cancelButtonText={cancelButtonText}
				nextButtonText={nextButtonText}
				onClickNext={onClickNext}
				wizardDescription={description}
				onCancel={cancelWizard}
				onOK={onOK}
				stepNumber={stepNumber}
				totalNumSteps={totalNumSteps}
				stepNames={steps
					.filter(
						step =>
							step.skipThisStep === undefined ||
							step.skipThisStep(wizardData, steps, step.key) === false
					)
					.map(step =>
						(step.shortTitle ? step.shortTitle : step.title).toLowerCase()
					)}
			>
				<Component
					wizardData={wizardData}
					setHaveSystemError={setHaveSystemError}
					progressToNextStepNow={handleMoveToNextStep}
					disableProgressToNextStep={disableProgressToNextStep}
					enableProgressToNextStep={enableProgressToNextStep}
					setOnClickNext={setOnClickNext}
				/>
			</Step>
		</Modal>
	);
}

export default ModalWizard;
