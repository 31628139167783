import React, { useState, useEffect, useContext } from "react";
import moment from "moment-timezone";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { CompletedWizardData } from "../BookingWizard";

import {
	Credit,
	flagValidationErrorsInCredit
} from "types-and-validators/flagValidationErrorsInCredit";
import {
	SingleSessionPrice,
	flagValidationErrorsInSingleSessionPrice
} from "types-and-validators/flagValidationErrorsInSingleSessionPrice";

import { SessionType } from "types-and-validators/flagValidationErrorsInSessionType";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldSelect from "components/FieldSelect";
import SelectedEventSummary from "components/SelectedEventSummary";
import { useAccountStatus } from "hooks/useAccountStatus";
import { formatSessionPriceAndDuration } from "services/formatSessionPriceAndDuration";
import { formatSessionDuration } from "services/formatSessionDuration";
import { useCredits } from "hooks/useCredits";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import terminology from "terminology.json";

import { useSessionTypesAndPrices } from "hooks/useSessionTypesAndPrices";
import { useDefaultTimezone } from "hooks/useDefaultTimezone";
import { validate } from "validation/validate";
import { FieldSelectContainer } from "./StepChooseSessionDuration.styles";

const defaultCountryCodeUntilAccountStatusLoads = "GB";

function StepChooseSessionDuration({
	wizardData: { mentorId, sessionType, eventToBook },
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<CompletedWizardData>) {
	const {
		isWaiting: sessionTypesAndPricesWaiting,
		isLoading: sessionTypesAndPricesLoading,
		isError: sessionTypesAndPricesError,
		output: sessionTypesAndPrices
	} = useSessionTypesAndPrices(mentorId);

	const defaultTimezone = useDefaultTimezone();
	const { locationCountryCode } = useAccountStatus();

	const [{ uid }] = useContext(AuthContext);

	const creditTypes =
		sessionType === undefined
			? []
			: sessionTypesAndPrices.packagePrices
					.filter(packagePrice => (packagePrice.sessionTypeId = sessionType.id))
					.filter(
						/* Filter out package-prices for identical sessionDurationMinutes, but different numbers of sessions */
						(value, index, self) =>
							index ===
							self.findIndex(
								element =>
									element.sessionDurationMinutes ===
									value.sessionDurationMinutes
							)
					)
					.map(({ sessionTypeId, sessionDurationMinutes }) => ({
						sessionTypeId,
						sessionDurationMinutes,
						spendingStatus: "available" as "available"
					}));

	const {
		isWaiting: creditsWaiting,
		isLoading: creditsLoading,
		isError: creditsError,
		output: credits
	} = useCredits({
		uid,
		creditTypes
	});

	if (!sessionType) {
		throw new Error("No session type");
	}

	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(
		generateOptions(
			sessionType,
			sessionTypesAndPrices,
			credits,
			locationCountryCode
				? locationCountryCode
				: defaultCountryCodeUntilAccountStatusLoads
		)
	);

	const pricesAndCreditsStr = JSON.stringify({
		sessionTypesAndPrices,
		credits
	});
	const sessionTypeStr = sessionType ? JSON.stringify(sessionType) : undefined;
	useEffect(() => {
		const { sessionTypesAndPrices, credits } = JSON.parse(pricesAndCreditsStr);

		const sessionTypeDecoded = sessionTypeStr
			? JSON.parse(sessionTypeStr)
			: undefined;

		if (!sessionTypeDecoded) {
			throw new Error("No session type available");
		}

		setCurrentOptions(
			generateOptions(
				sessionTypeDecoded,
				sessionTypesAndPrices,
				credits,
				locationCountryCode
					? locationCountryCode
					: defaultCountryCodeUntilAccountStatusLoads
			)
		);
	}, [
		pricesAndCreditsStr,
		setCurrentOptions,
		locationCountryCode,
		sessionTypeStr
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(opt => !!opt.selected);
		const selectedOptionValue = selectedOption
			? selectedOption.value
			: undefined;

		if (selectedOptionValue && typeof selectedOptionValue === "string") {
			if (!eventToBook) {
				throw new Error("No eventToBook");
			}

			const selectedOptionParsed: unknown = JSON.parse(selectedOptionValue);

			if (!inputIsSinglesessionPriceOrCredit(selectedOptionParsed, uid)) {
				console.log("Unexpected selected option format", selectedOptionParsed);
				throw new Error("Unexpected selected option format");
			}

			/* Update end-time of the session to account for what the user chose
		       in this step (otherwise, it would be one-hour after the start-time) */
			eventToBook.end = moment(eventToBook.start)
				.clone()
				.add(selectedOptionParsed.sessionDurationMinutes, "minutes")
				.toDate();

			const selectedDurationAndPrice = validate(selectedOptionParsed, {
				doValidate: flagValidationErrorsInSingleSessionPrice,
				auth: { uid }
			})
				? { ...selectedOptionParsed, type: "cash" as "cash" }
				: {
						...selectedOptionParsed,
						price: 0,
						currency: "-",
						type: "credit" as "credit"
				  };

			enableProgressToNextStep({
				selectedDurationAndPrice,
				eventToBook
			});
		} else {
			disableProgressToNextStep();
		}
	}, [
		enableProgressToNextStep,
		disableProgressToNextStep,
		currentOptions,
		eventToBook,
		uid
	]);

	return (
		<>
			{/* Don't show end-time because it is currently one hour after the start-time (but it should depend on what the user chooses in this step) */}
			<SelectedEventSummary
				eventToBook={eventToBook}
				showEndTime={false}
				timezone={defaultTimezone}
			/>
			<FieldSelectContainer>
				<FieldSelect
					allowMultipleSelections={false}
					isLoading={
						sessionTypesAndPricesLoading ||
						sessionTypesAndPricesWaiting ||
						creditsLoading ||
						creditsWaiting
					}
					isError={sessionTypesAndPricesError || creditsError}
					currentOptions={currentOptions}
					onNewCurrentOptions={setCurrentOptions}
				/>
			</FieldSelectContainer>
		</>
	);
}

export default StepChooseSessionDuration;

function generateOptions(
	sessionType: SessionType,
	sessionTypesAndPrices: ReturnType<typeof useSessionTypesAndPrices>["output"],
	credits: ReturnType<typeof useCredits>["output"],
	locationCountryCode: string
) {
	const creditOptions = [];
	for (const response of credits ? credits : []) {
		if (!response) {
			continue;
		}
		if (response.page.length === 0) {
			continue;
		}
		const credit = response.page[0];
		const numCredits = response.count;
		creditOptions.push({ ...credit, type: "credit" as "credit", numCredits });
	}

	const cashOptions = sessionTypesAndPrices.singleSessionPrices
		.filter(
			singleSessionPrice => singleSessionPrice.sessionTypeId === sessionType.id
		)
		.map(opt => ({ ...opt, type: "cash" as "cash" }));
	const allOptions = [...creditOptions, ...cashOptions].sort(
		(a, b) => a.sessionDurationMinutes - b.sessionDurationMinutes
	);

	const output = allOptions.map(option => {
		const value =
			option.type === "credit"
				? getValueFromCredit(option)
				: getValueFromSingleSessionPrice(option);
		const text =
			option.type === "credit"
				? formatSessionDuration({
						sessionDurationMinutes: option.sessionDurationMinutes
				  }) + ` (pay with credit - ${option.numCredits} remaining)`
				: formatSessionPriceAndDuration({
						singleSessionPrice: option,
						locationCountryCode
				  }) +
				  (option.guestUid ? ` - ${terminology.mentee}-specific price` : "");

		const jsonValue = JSON.stringify(value);
		return {
			id: jsonValue,

			// TODO:WV:20250305:Try to move away from jsonValue (which is just to pacify typescript) back to "value"
			value: jsonValue,
			text
		};
	});

	return output;
}

function getValueFromSingleSessionPrice({
	id,
	price,
	currency,
	sessionDurationMinutes
}: SingleSessionPrice) {
	return {
		id,
		price,
		currency,
		sessionDurationMinutes
	};
}

function getValueFromCredit({
	id,
	sessionDurationMinutes,
	sessionTypeId
}: Credit) {
	return {
		id,
		sessionDurationMinutes,
		sessionTypeId,
		type: "credit" as "credit"
	};
}

function inputIsSinglesessionPriceOrCredit(
	input: unknown,
	uid: string | undefined
): input is SingleSessionPrice | Credit {
	if (
		validate(input, {
			doValidate: flagValidationErrorsInSingleSessionPrice,
			auth: { uid }
		})
	) {
		return true;
	}
	if (
		validate(input, { doValidate: flagValidationErrorsInCredit, auth: { uid } })
	) {
		return true;
	}
	return false;
}
