import React, { useEffect, useState, useContext, useRef } from "react";

import { SearchResults } from "api/SearchResults";
import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import { DataInAnyRow } from "components/PageDashboard/PackagePricesSection/PackagePricesTable/PackagePricesTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { useSessionTypes, OutputRow } from "hooks/useSessionTypes";

function StepSessionType({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [{ isSignedIn, uid }] = useContext(AuthContext);

	const [dashboardContextState] = useContext(DashboardContext);

	const {
		isWaiting: sessionTypesWaiting,
		isLoading: sessionTypesLoading,
		isError: sessionTypesError,
		output: sessionTypes
	} = useSessionTypes({
		mentorId: uid,
		showDisabled: true,
		doWait: isSignedIn === undefined || !uid,
		repeatNumber: dashboardContextState.sessionTypesRefreshNum,
		persistBetweenLoads: true
	});

	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(
		getSessionTypeOptions(sessionTypes)
	);

	const { sessionTypesRefreshNum } = dashboardContextState;
	const sessionTypesRef = useRef(sessionTypes);
	sessionTypesRef.current = sessionTypes;
	useEffect(() => {
		if (!(sessionTypesWaiting || sessionTypesLoading || sessionTypesError)) {
			setCurrentOptions(oldOptions =>
				getSessionTypeOptions(
					sessionTypesRef.current,
					oldOptions ? oldOptions.find(option => option.selected) : undefined
				)
			);
		}
	}, [
		sessionTypesWaiting,
		sessionTypesLoading,
		sessionTypesError,
		sessionTypesRefreshNum,
		sessionTypesRef
	]);

	useEffect(() => {
		const selectedOption = currentOptions.find(
			currentOptions => !!currentOptions.selected
		);
		if (!selectedOption) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			sessionTypeId: selectedOption.value + ""
		});
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<FieldSelect
			allowMultipleSelections={false}
			currentOptions={currentOptions}
			onNewCurrentOptions={setCurrentOptions}
			useCheckboxes={true}
		/>
	);
}

export default StepSessionType;

function getSessionTypeOptions(
	sessionTypes: SearchResults<OutputRow> | undefined,
	selectedItem?: FieldOption
) {
	return sessionTypes
		? sessionTypes.page.map(({ id, sessionTitle }) => ({
				id,
				value: id,
				text: sessionTitle,
				selected: selectedItem ? id === selectedItem.value : false
		  }))
		: [];
}
