import React, { useState } from "react";
import { useParams, Redirect } from "react-router-dom";

// @ts-ignore
import Sticky from "react-sticky-el";

import PageContent from "components/PageContent";
import ErrorBanner from "components/ErrorBanner";
import MeetingCalendarHeadingBar from "components/MeetingCalendar/MeetingCalendarHeadingBar";
import { useUserExists } from "hooks/useUserExists";
import SectionBox from "components/SectionBox";
import { useAvailabilityCalendarData } from "hooks/useAvailabilityCalendarData";
import MessageDialog from "./MessageDialog";
import SessionsOffered from "./SessionsOffered";
import { useUserProfilePageTitlePrefix } from "./useUserProfilePageTitlePrefix";
import { useRedirectToVanityURL } from "./useRedirectToVanityURL";
import { useShowBookingInterface } from "./useShowBookingInterface";
import { useBookingWizard } from "./wizards/BookingWizard/useBookingWizard";
import { useBuyPackageWizard } from "./wizards/BuyPackageWizard/useBuyPackageWizard";
import { useMessageDialog } from "./useMessageDialog";
import Bio from "./Bio";
import Availability from "./Availability";
import UserProfileActionButtons from "./UserProfileActionButtons";
import { useProfileData } from "hooks/useProfileData";
import BookingWizard from "./wizards/BookingWizard";
import BuyPackageWizard from "./wizards/BuyPackageWizard";
import Heading from "./Heading";
import ProfileVideo from "./ProfileVideo";
import { useScreenSizeIsMin } from "hooks/useScreenSizeIsMin";

const PageUserProfile: React.FunctionComponent = () => {
	const { vanityUrl, uid } = useParams<{ vanityUrl: string; uid: string }>();

	const {
		bookingWizardInProgress,
		setBookingWizardInProgress,
		startBookingWizard,
		handleEndBookingWizard
	} = useBookingWizard(60, vanityUrl, uid);

	const {
		buyPackageWizardInProgress,
		startBuyPackageWizard,
		handleEndBuyPackageWizard
	} = useBuyPackageWizard(vanityUrl, uid);

	const {
		handleEndMessageDialog,
		handleClickSendAMessage,
		messageWizardInProgress
	} = useMessageDialog(vanityUrl, uid);

	useUserProfilePageTitlePrefix(vanityUrl, uid);

	useRedirectToVanityURL(vanityUrl, uid);
	useShowBookingInterface(setBookingWizardInProgress);

	const { isWaiting, isLoading, isError, output: profileData } = useProfileData(
		vanityUrl,
		uid
	);

	const mentorId =
		profileData && profileData.isMentor ? profileData.uid : undefined;

	const {
		availabilityCalendarData: rightColumnMiniAvailabilityCalendarData
	} = useAvailabilityCalendarData({
		mentorId,
		slotDurationInMinutes: 60
	});

	const [rightColumnIsStuck, setRightColumnIsStuck] = useState(false);

	const screenIsTabletOrWider = useScreenSizeIsMin("tablet");

	const { output: userExistenceData } = useUserExists({ uid, vanityUrl });
	if (userExistenceData && userExistenceData.userExists === false) {
		return <Redirect to="/" />;
	}

	if (isWaiting || isLoading || !profileData) {
		return <PageContent></PageContent>;
	}

	if (isError) {
		return (
			<PageContent>
				<ErrorBanner />
			</PageContent>
		);
	}

	// TODO;WV:20200817:Prevent flash of profile without booking wizard when returning after linking zoom
	return (
		<PageContent>
			<SectionBox containsFixedRightColumn={rightColumnIsStuck}>
				<div className="row gtr-uniform">
					<div className="col-8 col-12-small">
						<Heading />
					</div>
					{screenIsTabletOrWider &&
					!rightColumnIsStuck &&
					mentorId !== undefined ? (
						<div className="col-4 col-12-small d-xs-none">
							<MeetingCalendarHeadingBar
								displaymode="compact"
								onNavigate={rightColumnMiniAvailabilityCalendarData.onNavigate}
								weekOffset={rightColumnMiniAvailabilityCalendarData.weekOffset}
								firstDateInFloatingTimezone={
									rightColumnMiniAvailabilityCalendarData.firstDateInFloatingTimezone
								}
								titleShort="Availability"
								titleLong="Availability"
							/>
						</div>
					) : null}
				</div>

				{mentorId === undefined ? null : (
					<div className="row gtr-uniform">
						<div className="col-8 col-12-small">
							<ProfileVideo />

							<Bio vanityUrl={vanityUrl} uid={uid} />

							<UserProfileActionButtons
								vanityUrl={vanityUrl}
								uid={uid}
								mentorId={mentorId}
								startBookingWizard={startBookingWizard}
								startBuyPackageWizard={startBuyPackageWizard}
								handleClickSendAMessage={handleClickSendAMessage}
								showChatOption={true}
								fitWidth={false}
							/>

							<SessionsOffered mentorId={mentorId} />

							<UserProfileActionButtons
								vanityUrl={vanityUrl}
								uid={uid}
								mentorId={mentorId}
								startBookingWizard={startBookingWizard}
								startBuyPackageWizard={startBuyPackageWizard}
								handleClickSendAMessage={handleClickSendAMessage}
								showChatOption={true}
								fitWidth={false}
							/>
						</div>
						{screenIsTabletOrWider ? (
							<div className="col-4 d-small-none rightColumn">
								<Sticky
									boundaryElement=".rightColumn"
									onFixedToggle={setRightColumnIsStuck}
								>
									<Availability
										vanityUrl={vanityUrl}
										uid={uid}
										mentorId={mentorId}
										startBookingWizard={startBookingWizard}
										handleEndBookingWizard={handleEndBookingWizard}
										isMiniFormat
										availabilityCalendarData={
											rightColumnMiniAvailabilityCalendarData
										}
										includeHeadingBar={!!rightColumnIsStuck}
									/>

									<UserProfileActionButtons
										vanityUrl={vanityUrl}
										uid={uid}
										mentorId={mentorId}
										startBookingWizard={startBookingWizard}
										startBuyPackageWizard={startBuyPackageWizard}
										handleClickSendAMessage={handleClickSendAMessage}
										showChatOption={true}
										fitWidth={true}
									/>
								</Sticky>
							</div>
						) : null}
					</div>
				)}
			</SectionBox>

			{mentorId === undefined ? null : (
				<>
					<Availability
						vanityUrl={vanityUrl}
						uid={uid}
						mentorId={mentorId}
						startBookingWizard={startBookingWizard}
						handleEndBookingWizard={handleEndBookingWizard}
						isMiniFormat={!screenIsTabletOrWider}
					/>

					<BookingWizard
						mentorId={profileData.uid}
						mentorDisplayName={profileData.displayName}
						isInProgress={bookingWizardInProgress.isInProgress}
						selectedEvent={bookingWizardInProgress.selectedItem}
						currentUserUidWhenStarted={bookingWizardInProgress.currentUserUid}
						hasAcceptedTermsWhenStarted={
							bookingWizardInProgress.hasAcceptedTerms
						}
						onCancel={handleEndBookingWizard}
						onSent={handleEndBookingWizard}
						onFailed={handleEndBookingWizard}
					/>

					<BuyPackageWizard
						mentorId={profileData.uid}
						mentorDisplayName={profileData.displayName}
						isInProgress={buyPackageWizardInProgress.isInProgress}
						currentUserUidWhenStarted={
							buyPackageWizardInProgress.currentUserUid
						}
						hasAcceptedTermsWhenStarted={
							buyPackageWizardInProgress.hasAcceptedTerms
						}
						onCancel={handleEndBuyPackageWizard}
						onSent={handleEndBuyPackageWizard}
						onFailed={handleEndBuyPackageWizard}
					/>
				</>
			)}

			<MessageDialog
				mentorId={profileData.uid}
				isInProgress={messageWizardInProgress}
				onCancel={handleEndMessageDialog}
				onSent={handleEndMessageDialog}
				vanityUrl={vanityUrl}
				uid={uid}
			/>

			<UserProfileActionButtons
				vanityUrl={vanityUrl}
				uid={uid}
				mentorId={mentorId}
				startBookingWizard={startBookingWizard}
				startBuyPackageWizard={startBuyPackageWizard}
				handleClickSendAMessage={handleClickSendAMessage}
				showChatOption={true}
				fitWidth={false}
			/>
		</PageContent>
	);
};

export default PageUserProfile;
