import { useCallback, useContext, useMemo } from "react";
import moment from "moment-timezone";

import { LocalStorageContext } from "components/LocalStorageProvider/LocalStorageProvider";

export function useMeetingCalendarTimezone(): [
	string,
	(newvalue: string) => void
] {
	const localStorageKey = "meeting-calendar-timezone";
	const [get, set] = useContext(LocalStorageContext);

	const valueFromLocalStorage = get(localStorageKey);

	const timezone = useMemo(
		() => (valueFromLocalStorage ? valueFromLocalStorage : moment.tz.guess()),
		[valueFromLocalStorage]
	);

	const setTimezone = useCallback(
		(newvalue: string) => {
			set(localStorageKey, newvalue);
		},
		[localStorageKey, set]
	);

	return [timezone, setTimezone];
}
