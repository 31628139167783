import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import { Term, validateTerms } from "api/getUserProfile";
import {
	StripeAccountOnboardingStatus,
	isValidStripeAccountOnboardingStatus
} from "services/stripe/isValidStripeAccountOnboardingStatus";
import { validate, ValidationCallback } from "validation/validate";

interface GetAccountStatusParams {
	uid?: string;
}

interface RawApiOutput {
	settings: {
		timezone: string;
		locationDistrict: string;
		locationCountryCode: string;
		displayName: string;
		mentorProfileAboutMe: string;
		hasDismissedStripeAlert: boolean;
		defaultCurrencyCode: string;
		skypeId: string;
		defaultGoogleMeetLink: string;
		hasPaidZoomAccount: boolean;

		paymentProvidersMBWay: string;
		paymentProvidersWisetag: string;
		paymentProvidersCustom: string;
	};
	links: {
		hasLinkedZoomAccount: boolean;
		stripeAccountOnboardingStatus: StripeAccountOnboardingStatus;
	};
	profileData: {
		isMentor: boolean;
		locationCountryName: string;
		terms: Term[];
		icalKey: string;
	};
}

export type Output = RawApiOutput["settings"] &
	RawApiOutput["links"] &
	RawApiOutput["profileData"];

const validateSettings: ValidationCallback<RawApiOutput["settings"]> = (
	input,
	{ flag, checkField },
	auth
) => {
	checkField("timezone", { type: "string" });
	checkField("locationDistrict", { type: "string" });
	checkField("locationCountryCode", { type: "string" });
	checkField("displayName", { type: "string" });
	checkField("mentorProfileAboutMe", { type: "string" });
	checkField("hasDismissedStripeAlert", { type: "boolean" });
	checkField("defaultCurrencyCode", { type: "string" });
	checkField("skypeId", { type: "string" });
	checkField("defaultGoogleMeetLink", { type: "string" });
	checkField("hasPaidZoomAccount", { type: "boolean" });
};

const validateLinks: ValidationCallback<RawApiOutput["links"]> = (
	input,
	{ flag, checkField },
	auth
) => {
	checkField("hasLinkedZoomAccount", { type: "boolean" });
	checkField("stripeAccountOnboardingStatus", {
		type: "string",
		followUpChecks: ({ value, findInvalid }) => {
			if (!isValidStripeAccountOnboardingStatus(value)) {
				findInvalid("Invalid status");
			}
		}
	});
};

const validateProfileData: ValidationCallback<RawApiOutput["profileData"]> = (
	input,
	{ flag, checkField },
	auth
) => {
	checkField("icalKey", { type: "string" });
	checkField("isMentor", { type: "boolean" });
	checkField("locationCountryName", { type: "string" });
	validate(input, {
		doValidate: validateTerms,
		withErrors: errors => {
			flag("terms", `terms: ${errors.terms ? errors.terms : `unknown error`}`);
		},
		auth
	});
};

const flagValidationErrors: ValidationCallback<RawApiOutput> = (
	input,
	{ flag, checkField },
	auth
) => {
	const { settings, links, profileData } = input;

	validate(settings, {
		doValidate: validateSettings,
		withErrors: errors => {
			flag("settings", JSON.stringify(errors));
		},
		auth
	});

	validate(links, {
		doValidate: validateLinks,
		withErrors: errors => {
			flag("links", JSON.stringify(errors));
		},
		auth
	});

	validate(profileData, {
		doValidate: validateProfileData,
		withErrors: errors => {
			flag("profileData", JSON.stringify(errors));
		},
		auth
	});
};

export function getAccountStatus(
	{ uid }: GetAccountStatusParams,
	auth: Auth
): ApiRequest<Output> {
	const req = makeApiRequest("accountStatus", {
		auth,
		waitForTimezone: false,
		data: {
			get: {
				uid
			}
		},
		flagValidationErrors
	});

	const ready = req.ready.then(result =>
		result
			? {
					...result.settings,
					...result.links,
					...result.profileData
			  }
			: undefined
	);
	const abort = () => req.abort();
	const aborted = () => req.aborted();

	return {
		ready,
		abort,
		aborted,
		type: req.type
	};
}
