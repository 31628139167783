import React, { useEffect, useCallback, useMemo, useState } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { DataInAnyRow } from "components/PageDashboard/PackagePricesSection/PackagePricesTable/PackagePricesTable";
import { usePaginatedContacts } from "hooks/usePaginatedContacts";

function StepGuestUid({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [selectedValue, setSelectedValue] = useState<string | null>(null);

	const {
		haveNextPage,
		havePreviousPage,
		goToNextPage,
		goToPreviousPage,
		options
	} = usePaginatedContacts(selectedValue, undefined, "mentee");

	const optionsStr = JSON.stringify(options);
	const onNewCurrentOptions = useCallback(
		(newState: React.SetStateAction<FieldOption[]>) => {
			const opts: FieldOption[] = JSON.parse(optionsStr);
			const entries =
				typeof newState === "function" ? newState(opts) : newState;
			const selectedEntry = entries.find(entry => !!entry.selected);
			if (selectedEntry) {
				if (
					selectedEntry.value !== null &&
					typeof selectedEntry.value !== "string"
				) {
					throw new Error("Invalid selected entry");
				}
				setSelectedValue(selectedEntry.value);
			} else {
				setSelectedValue(null);
			}
		},
		[optionsStr]
	);

	const pagination = useMemo(
		() => ({
			havePreviousPage,
			onClickPreviousPage: goToPreviousPage,
			haveNextPage,
			onClickNextPage: goToNextPage
		}),
		[havePreviousPage, goToPreviousPage, haveNextPage, goToNextPage]
	);

	useEffect(() => {
		const opts: FieldOption[] = JSON.parse(optionsStr);
		const selectedOption = opts.find(opt => !!opt.selected);
		if (!selectedOption) {
			disableProgressToNextStep();
			return;
		}

		if (
			!(
				selectedOption.value === undefined ||
				selectedOption.value === null ||
				typeof selectedOption.value === "string"
			)
		) {
			throw new Error("Invalid selected option");
		}

		enableProgressToNextStep({ guestUid: selectedOption.value });
	}, [enableProgressToNextStep, disableProgressToNextStep, optionsStr]);

	return (
		<FieldSelect
			allowMultipleSelections={false}
			currentOptions={options}
			onNewCurrentOptions={onNewCurrentOptions}
			useCheckboxes={true}
			pagination={pagination}
		/>
	);
}

export default StepGuestUid;
