import React, { useCallback, useContext, useMemo } from "react";

import { AuthContext } from "components/AuthProvider/AuthProvider";
import { AlertContext } from "components/AlertProvider/AlertProvider";
import {
	MeetingCalendarEvent,
	SlotDurationInMinutes
} from "components/MeetingCalendar/MeetingCalendar";
import ModalWizard from "components/ModalWizard";
import { ModalWizardStep } from "components/ModalWizard/ModalWizard";
import StepConfirmMeetingToReschedule from "./StepConfirmMeetingToReschedule";
import StepChooseTimeSlot from "./StepChooseTimeSlot";
import StepConfirmAndAddMessage from "./StepConfirmAndAddMessage";
import { captureException } from "services/captureException";
import { sendMeetingRescheduleRequest } from "api/sendMeetingRescheduleRequest";
import { useSendData } from "hooks/useSendData";
import { ucFirst } from "utils/ucFirst";
import {
	validate,
	ValidationCallback,
	validationErrorsToString
} from "validation/validate";
import terminology from "terminology.json";
import { OutputRow as MeetingsDataFlattened } from "api/getMeetingsFlattened";

interface Props {
	meeting: MeetingsDataFlattened | undefined;
	isInProgress: boolean;
	onCancel: () => void;
	onSent: () => void;
	onFailed: () => void;
}

type NewSessionEvent = Pick<MeetingCalendarEvent, "start" | "end">;

interface CompletedWizardData {
	mentorId: string;
	meeting: Props["meeting"];
	newSessionEvent: NewSessionEvent;
	message?: string;
	slotDurationInMinutes: SlotDurationInMinutes;
}

export type WizardData = Partial<CompletedWizardData>;

const RescheduleMeetingWizard: React.FunctionComponent<Props> = ({
	meeting,
	isInProgress,
	onCancel: onCancelWizard,
	onSent,
	onFailed
}) => {
	const [addAlert] = useContext(AlertContext);
	const [{ uid }] = useContext(AuthContext);

	const doRequest = useSendData({
		method: sendMeetingRescheduleRequest,
		onFailure: e => {
			// Do nothing here
		}
	});

	const meetingId = meeting ? meeting.id : undefined;
	const handleSubmit = useCallback(
		(data: CompletedWizardData) => {
			const { newSessionEvent, message } = data;

			if (!uid) {
				throw new Error("No requester uid");
			}

			if (!meetingId) {
				throw new Error("No meeting ID");
			}

			const errAlert = {
				title: "Transmission error",
				contents: `Unfortunately there was an error saving your reschedule request.  Please try again later, and if the problem persists, consider letting us know.`
			};

			const req = doRequest(
				{
					meetingId,
					reschedulerUid: uid,
					message,
					newSessionEvent
				},
				{ uid }
			);

			const ready = req.ready
				.then(() => {
					addAlert({ contents: "Reschedule request received - thank you." });
					onSent();
				})
				.catch(e => {
					captureException(e, {
						evtType: "rescheduleRequestFailed",
						extra: {
							newSessionEvent,
							message,
							originalError: e
						}
					});
					addAlert(errAlert);
					onFailed();
				});

			return {
				ready,
				abort: () => req.abort(),
				aborted: () => req.aborted()
			};
		},
		[addAlert, onSent, onFailed, meetingId, uid, doRequest]
	);

	const mentorId = meeting ? meeting.hostUid : undefined;

	const steps = useMemo(() => {
		return [
			{
				key: "confirm-meeting-to-reschedule",
				title: `Confirm ${terminology.meeting} to reschedule`,
				component: StepConfirmMeetingToReschedule
			},
			{
				key: "time-slot",
				title: "Choose time slot",
				component: StepChooseTimeSlot
			},
			{
				key: "message",
				title: "Confirm new date/time and add message",
				component: StepConfirmAndAddMessage,
				nextButtonText: (
					wizardData: WizardData,
					steps: ModalWizardStep<WizardData>[],
					thisStepKey: string
				) => {
					const thisStepIndex = steps.findIndex(
						step => step.key === thisStepKey
					);
					if (thisStepIndex === -1) {
						captureException(new Error("Step not found"), {
							evtType: "stepNotFound",
							extra: { steps, thisStepKey }
						});
						return "Next";
					}

					const subsequentVisibleSteps = steps
						.slice(thisStepIndex + 1)
						.filter(step =>
							step.skipThisStep
								? !step.skipThisStep(wizardData, steps, step.key)
								: true
						);

					if (subsequentVisibleSteps.length === 0) {
						return "Confirm and request reschedule";
					}

					return "Next";
				}
			}
		];
	}, []);

	const validateCompletedData: ValidationCallback<WizardData> = useCallback(
		(input, { flag }) => {
			const { newSessionEvent, message } = input;

			if (!newSessionEvent) {
				flag("newSessionEvent", "missing");
			} else {
				const doValidate: ValidationCallback<NewSessionEvent> = (
					input,
					{ checkField }
				) => {
					checkField("start", { type: "date" });
					checkField("end", { type: "date" });
				};
				validate(newSessionEvent, {
					auth: { uid },
					doValidate,
					withErrors: eventErrors => {
						flag("newSessionEvent", validationErrorsToString(eventErrors));
					}
				});
			}

			if (message) {
				if (typeof message !== "string") {
					flag("message", "not a string");
				}
			}
		},
		[uid]
	);

	if (!mentorId) {
		return null;
	}

	return (
		<ModalWizard<CompletedWizardData>
			isInProgress={!!isInProgress}
			cancelButtonText="Cancel request"
			onCancel={onCancelWizard}
			onComplete={handleSubmit}
			description={`Reschedule ${ucFirst(terminology.meeting)}`}
			validateCompletedData={validateCompletedData}
			initialData={{ mentorId, meeting }}
			steps={steps}
		/>
	);
};

export default RescheduleMeetingWizard;
