import queryString from "query-string";
import moment from "moment-timezone";
import delay from "delay";

import { abortableFetch } from "services/abortableFetch";
import { removeUndefined } from "utils/removeUndefined";
import { getAuthToken } from "services/getAuthToken";
import { on, emit } from "services/pubSub";
import { getAccountStatusPromise } from "services/accountStatus";
import { generateHashCode } from "services/generateHashCode";
import { ApiRequest } from "api/ApiRequest";
import { ValidationCallback, validate } from "validation/validate";
import {
	captureException,
	CapturedElsewhereError
} from "services/captureException";
import { safeTsNotNullish } from "utils/safeTsNotNullish";

import { cacheGet, cacheSet } from "./cache";
import { sendBatched } from "./sendBatched";

// Config
const defaultEnableBatching =
	process.env.REACT_APP_ENABLE_API_REQUEST_BATCHING === "1";

type ApiRequestParams = {
	[k: string]: string | undefined;
};

export interface ApiRequestData {
	get?: ApiRequestParams;
	post?: any;
}

export interface Auth {
	uid?: string;
}

export type ApiRequestConfig<T> = {
	cacheInMemory?: boolean;
	waitForTimezone?: boolean;
	data?: ApiRequestData;
	flagValidationErrors: ValidationCallback<T>;
	auth: Auth;
	enableBatching?: boolean;
};

export type ApiRequestPageConfig<T> = Omit<ApiRequestConfig<T>, "pagination">;

export interface DebounceCompletePubSubMessage {
	key: string;
	value: unknown;
}

const debouncers: {
	[k: string]: number;
} = {};
const debounceThresholdInMs = 25;

// Shared hosting simulation
const simulateSleepySharedHosting = false;
let haveMadeAtLeastOneRequest = false;
// /Shared hosting simulation

export function makeApiRequest<T>(
	endpoint: string,
	{
		cacheInMemory,
		waitForTimezone = true,
		flagValidationErrors,
		data = {},
		auth,

		// TODO:WV:20230331:Enable page-specific batching (i.e. only on the dashboard)
		enableBatching = defaultEnableBatching
	}: ApiRequestPageConfig<T>
): ApiRequest<T> {
	let aborted: boolean = false;
	let fetcher: Fetcher | undefined;
	let unbindDebouncer: () => void | undefined;

	const isPostRequest =
		data.post !== undefined && Object.values(data.post).length !== 0;

	const enableDebounce = !isPostRequest;
	const debounceKey = endpoint + generateHashCode(JSON.stringify(data));

	async function fetchAndValidateData(): Promise<T | undefined> {
		function validateRetrievedData(retrievedData: unknown): retrievedData is T {
			const isCorrectDataType = validate<T>(retrievedData, {
				doValidate: flagValidationErrors,
				auth,
				withErrors: errors => {
					throw captureException(new Error("Invalid data"), {
						evtType: "invalidApiData",
						extra: {
							retrievedData,
							errors,
							endpoint
						}
					});
				}
			});

			return isCorrectDataType;
		}

		// Debounce
		// TODO:WV:20230330:Tidy this up
		if (enableDebounce) {
			// If debouncer exists, don't progress any further and
			// instead wait until the original API request completes
			if (debouncers[debounceKey]) {
				return new Promise((resolve, reject) => {
					// TODO:WV:20230331:Remove 'any'
					unbindDebouncer = on(
						"debounceComplete",
						({
							key: keyFromOriginalRequest,
							value: valueFromOriginalRequest
						}: DebounceCompletePubSubMessage) => {
							if (keyFromOriginalRequest !== debounceKey) {
								return;
							}

							if (!validateRetrievedData(valueFromOriginalRequest)) {
								throw new CapturedElsewhereError(
									"Invalid value from original request"
								);
							}

							resolve(valueFromOriginalRequest);
							unbindDebouncer();
						}
					);
				});
			}

			// If debouncer does not exist, create it and leave it in
			// existence until the end of the debounce period
			if (!debouncers[debounceKey]) {
				debouncers[debounceKey] = window.setTimeout(() => {
					delete debouncers[debounceKey];
				}, debounceThresholdInMs);
			}

			// Wait until the end of the debounce period before proceeding with the API request.
			// This is to ensure that the actual HTTP request is sent after all the debounced requests
			// have been collected, and so that they all receive the most recent version of the data.
			await delay(debounceThresholdInMs);
		}

		if (cacheInMemory) {
			const valueFromCache: unknown = cacheGet({ endpoint, data });
			if (valueFromCache !== undefined) {
				if (!validateRetrievedData(valueFromCache)) {
					throw new CapturedElsewhereError("Invalid value from cache");
				}
				if (enableDebounce) {
					emit("debounceComplete", { key: debounceKey, value: valueFromCache });
				}
				return valueFromCache;
			}
		}

		if (aborted) {
			if (enableDebounce) {
				if (debouncers[debounceKey]) {
					window.clearTimeout(safeTsNotNullish(debouncers[debounceKey]));
					delete debouncers[debounceKey];
				}
			}
			return;
		}

		let response: any;

		try {
			const isPostRequest =
				data.post !== undefined && Object.values(data.post).length !== 0;

			if (simulateSleepySharedHosting) {
				if (!haveMadeAtLeastOneRequest) {
					haveMadeAtLeastOneRequest = true;
					await delay(10000);
				}
			}

			if (enableBatching && !isPostRequest) {
				response = await sendBatched({
					// TODO:WV:20230330:Send only data.get, not whole data (to shorten query string)
					req: { endpoint, data },
					auth
				});
			} else {
				const fetcher = await startRequest(endpoint, data, { waitForTimezone });
				response = await (await fetcher.ready).json();
			}
		} catch (e) {
			if (!aborted) {
				throw captureException(new Error("Error fetching data"), {
					evtType: "invalidApiResponse",
					extra: {
						originalException: e,
						endpoint
					}
				});
			}
		}

		if (!response) {
			throw captureException(new Error("Response was empty"), {
				evtType: "responseWasEmpty",
				extra: {
					endpoint,
					data
				}
			});
		}

		if (typeof response !== "object") {
			throw captureException(new Error("Response was not an object"), {
				evtType: "responseWasNotAnObject",
				extra: {
					endpoint,
					data,
					response
				}
			});
		}

		if (response.status !== "ok") {
			throw captureException(new Error("Response status was not 'ok'"), {
				evtType: "apiResponseStatusNotOK",
				extra: {
					response,
					endpoint,
					data
				}
			});
		}

		const apiOutput: unknown = "data" in response ? response.data : {};

		if (!validateRetrievedData(apiOutput)) {
			throw new CapturedElsewhereError("Invalid response");
		}

		if (cacheInMemory) {
			cacheSet({ endpoint, data, value: response.data });
		}

		if (enableDebounce) {
			emit("debounceComplete", { key: debounceKey, value: response.data });
		}

		return apiOutput;
	}

	return {
		ready: fetchAndValidateData(),
		abort: () => {
			aborted = true;
			if (fetcher) {
				fetcher.abort();
			}
			if (enableDebounce) {
				if (unbindDebouncer) {
					unbindDebouncer();
				}
			}
		},
		aborted: () => {
			return aborted;
		},
		type: endpoint
	};
}

interface Fetcher {
	abort: () => void;
	aborted: () => boolean;
	ready: Promise<Response>;
}

async function startRequest(
	endpoint: string,
	{ get, post }: ApiRequestData,
	{ waitForTimezone }: { waitForTimezone?: boolean }
): Promise<Fetcher> {
	const queryParams = get ? removeUndefined(get) : {};

	const fullUrl = `${process.env.REACT_APP_API_URL}/api/${endpoint}${
		Object.keys(queryParams).length
			? "?" + queryString.stringify(queryParams)
			: ""
	}`;

	const authToken = await getAuthToken();

	const { timezone: tz } = waitForTimezone
		? await getAccountStatusPromise()
		: { timezone: undefined };
	const timezone = tz ? tz : moment.tz.guess();

	const fetcher = abortableFetch(fullUrl, {
		method: post ? "POST" : "GET",
		headers: {
			Accept: "application/json",
			...(timezone ? { x_timezone: timezone } : {}),
			...(post
				? {
						"Content-Type": "application/json"
				  }
				: {}),
			...(authToken
				? {
						Authorization: `Bearer ${authToken}`
				  }
				: {})
		},
		...(post
			? {
					body: JSON.stringify(post)
			  }
			: {})
	});

	return fetcher;
}
