import React, { useState, useCallback, useMemo, useEffect } from "react";

import { getAllLocalStorage } from "./getAllLocalStorage";

type Getter = (key: string) => string | null;
type Setter = (key: string, value: string | null) => void;

type LocalStorageMethods = [Getter, Setter];

export const LocalStorageContext = React.createContext<LocalStorageMethods>([
	() => null,
	() => {}
]);

const LocalStorageProvider: React.FunctionComponent = ({ children }) => {
	const initialLocalStorage = useMemo(() => getAllLocalStorage(), []);
	const [state, setState] = useState<{ [k: string]: string }>(
		initialLocalStorage
	);

	const get: Getter = (key: string) => (key in state ? state[key] : null);

	const replaceInState = useCallback(
		(key: string, value: string | null) => {
			setState(oldState => {
				const { [key]: _, ...rest } = oldState;
				if (value === null) {
					return rest;
				} else {
					return {
						...rest,
						[key]: value
					};
				}
			});
		},
		[setState]
	);

	// Respond to changes to localStorage made in other tabs and windows
	useEffect(() => {
		window.addEventListener("storage", e => {
			if (e.key === null) {
				// "null" key should mean that localStorage was cleared
				// but reload it from localStorage just in case I have missed an edge case
				setState(oldState => getAllLocalStorage());
			} else {
				replaceInState(e.key, e.newValue);
			}
		});
	}, [replaceInState]);

	const set: Setter = useCallback(
		(key: string, value: string | null) => {
			if (value === null) {
				localStorage.removeItem(key);
			} else {
				localStorage.setItem(key, value);
			}
			replaceInState(key, value);
		},
		[replaceInState]
	);

	return (
		<LocalStorageContext.Provider value={[get, set]}>
			{children}
		</LocalStorageContext.Provider>
	);
};

export default LocalStorageProvider;
