import moment from "moment-timezone";

import {
	Output as ProfileData,
	RawApiOutput as RawApiProfileData
} from "./getUserProfile";

// TODO:WV:20210409:Profile photo versioning (perhaps store whole URL in the database)
export function userProfileFromApiToLocal(
	apiOutput: RawApiProfileData<ProfileData>
): ProfileData {
	const {
		avatarVersion,
		videoVersion,
		lastMeetingDateUTC,
		...rest
	} = apiOutput;

	return {
		...rest,
		...(lastMeetingDateUTC
			? { lastMeetingDateUTC: moment.utc(lastMeetingDateUTC).toDate() }
			: {}),
		avatarUrl:
			avatarVersion === null
				? undefined
				: process.env.REACT_APP_API_URL +
				  `/userdata/public/${apiOutput.uid}/avatar.jpeg?v=${avatarVersion}`,
		videoUrl:
			videoVersion === null
				? undefined
				: process.env.REACT_APP_API_URL +
				  `/userdata/public/${apiOutput.uid}/video.mp4?v=${videoVersion}`,
		videoPosterUrl:
			videoVersion === null
				? undefined
				: process.env.REACT_APP_API_URL +
				  `/userdata/public/${apiOutput.uid}/video-poster.jpeg?v=${videoVersion}`
	};
}
