import moment from "moment";

import { formatDateForApi } from "services/formatDateForApi";

import { makeApiRequest, Auth } from "api/makeApiRequest";
import { ApiRequest } from "api/ApiRequest";
import {
	ValidationCallback,
	validate,
	validationErrorsToString
} from "validation/validate";
import { SearchResults } from "api/SearchResults";
import { flagValidationErrorsInSearchResults } from "validation/flagValidationErrorsInSearchResults";
import { Medium } from "hooks/useAvailableMediums";
import {
	flagValidationErrorsInSingleSessionPrice,
	SingleSessionPrice
} from "types-and-validators/flagValidationErrorsInSingleSessionPrice";
import {
	flagValidationErrorsInCredit,
	Credit
} from "types-and-validators/flagValidationErrorsInCredit";
import { SessionType } from "types-and-validators/flagValidationErrorsInSessionType";

type PaymentStatus = "free" | "pending" | "awaiting-webhook" | "ok";

interface RawApiOutputBaseData {
	id: string;
	dateStartsUTC: string;
	dateEndsJustBeforeUTC: string;
}

// TODO:WV:20230421:Make sure these are all validated correctly
export interface AttendeeData {
	hostUid: string;
	guestUid: string;
	displayNameHost: string;
	displayNameGuest: string;
	paymentStatus: PaymentStatus;
	singleSessionPrice: SingleSessionPrice;
	credit: Credit;
	sessionType: SessionType;
	isSignedOffByGuest: boolean;
	isSignedOffBySystem: boolean;
	medium: Medium;
	joinURL: string | undefined;
	hostSkypeId: string | undefined;
	guestSkypeId: string | undefined;
}

export interface HostData {
	startURL: string | undefined;
}

interface RawApiOutputRow extends RawApiOutputBaseData {
	attendeeData?: AttendeeData;
	hostData?: HostData;
}

const flagValidationErrorsInAttendeeData: ValidationCallback<AttendeeData> = (
	input,
	{ flag, checkField },
	{ uid }
) => {
	checkField("hostUid", { type: "string" });
	checkField("guestUid", { type: "string" });
	checkField("displayNameHost", { type: "string" });
	checkField("displayNameGuest", { type: "string" });
	checkField("medium", { type: "string" });
	checkField("paymentStatus", {
		type: "enum",
		allowedValues: ["free", "pending", "awaiting-webhook", "ok"],
		isRequired: true
	});

	if (input.singleSessionPrice) {
		validate(input.singleSessionPrice, {
			doValidate: flagValidationErrorsInSingleSessionPrice,
			withErrors: errors => {
				flag("singleSessionPrice", validationErrorsToString(errors));
			},
			auth: { uid }
		});
	}

	if (input.credit) {
		validate(input.credit, {
			doValidate: flagValidationErrorsInCredit,
			withErrors: errors => {
				flag("credit", validationErrorsToString(errors));
			},
			auth: { uid }
		});
	}

	checkField("isSignedOffByGuest", {
		type: "boolean"
	});
	checkField("isSignedOffBySystem", {
		type: "boolean"
	});
	checkField("joinURL", {
		type: "string",
		isRequired: false
	});

	checkField("hostSkypeId", {
		type: "string",
		isRequired: false
	});

	checkField("guestSkypeId", {
		type: "string",
		isRequired: false
	});
};

const flagValidationErrorsInHostData: ValidationCallback<HostData> = (
	input,
	{ flag, checkField },
	{ uid }
) => {
	checkField("startURL", {
		type: "string",
		isRequired: false
	});
};

const flagValidationErrorsInRow: ValidationCallback<RawApiOutputRow> = (
	input,
	{ flag, checkField },
	auth
) => {
	checkField("id", { type: "string" });
	checkField("dateStartsUTC", { type: "datestring" });
	checkField("dateEndsJustBeforeUTC", { type: "datestring" });

	const { uid } = auth;
	const userIsHost = !!uid && (!!input.hostUid && input.hostUid === uid);
	const userIsGuest = !!uid && (!!input.guestUid && input.guestUid === uid);
	const isOwnMeeting = !!userIsHost || !!userIsGuest;

	if (isOwnMeeting) {
		const { attendeeData } = input;
		if (!attendeeData) {
			flag("attendeeData", "missing");
		} else {
			validate(input.attendeeData, {
				auth,
				doValidate: flagValidationErrorsInAttendeeData,
				withErrors: errors => {
					flag("attendeeData", JSON.stringify(errors));
				}
			});
		}
	}

	if (userIsHost) {
		const { hostData } = input;
		if (!hostData) {
			flag("hostData", "missing");
		} else {
			validate(input.hostData, {
				auth,
				doValidate: flagValidationErrorsInHostData,
				withErrors: errors => {
					flag("hostData", JSON.stringify(errors));
				}
			});
		}
	}
};

const flagValidationErrors: ValidationCallback<
	SearchResults<RawApiOutputRow>
> = (input, flag, auth) =>
	flagValidationErrorsInSearchResults(
		flagValidationErrorsInRow,
		input,
		flag,
		auth
	);

export interface OutputRow
	extends Omit<RawApiOutputRow, "dateStartsUTC" | "dateEndsJustBeforeUTC"> {
	dateStartsUTC: Date;
	dateEndsJustBeforeUTC: Date;
}

export interface Params {
	id?: string;
	hostUid?: string;
	guestUid?: string;
	earliestStartDateUTC?: Date;
	mustStartBeforeUTC?: Date;
	mustEndAfterUTC?: Date;
	mustEndBeforeUTC?: Date;
	paymentStatus?: PaymentStatus;
	onlyMeetingsThatNeedSignOff?: boolean;
	isSignedOff?: boolean;
	cacheInMemory?: boolean;
}

export function getMeetings(
	{
		id,
		hostUid,
		guestUid,
		earliestStartDateUTC,
		mustStartBeforeUTC,
		mustEndAfterUTC,
		mustEndBeforeUTC,
		paymentStatus,
		onlyMeetingsThatNeedSignOff,
		cacheInMemory
	}: Params,
	auth: Auth
): ApiRequest<SearchResults<OutputRow>> {
	const req = makeApiRequest("meetings", {
		cacheInMemory,
		auth,
		data: {
			get: {
				...(id ? { id } : {}),
				...(hostUid ? { hostUid } : {}),
				...(guestUid ? { guestUid } : {}),
				...(paymentStatus ? { paymentStatus } : {}),
				...(!!onlyMeetingsThatNeedSignOff
					? { isSignedOff: "0", mustEndBeforeUTC: formatDateForApi(new Date()) }
					: {}),
				...(earliestStartDateUTC
					? { earliestStartDateUTC: formatDateForApi(earliestStartDateUTC) }
					: {}),
				...(mustStartBeforeUTC
					? { mustStartBeforeUTC: formatDateForApi(mustStartBeforeUTC) }
					: {}),
				...(mustEndAfterUTC
					? { mustEndAfterUTC: formatDateForApi(mustEndAfterUTC) }
					: {}),
				...(mustEndBeforeUTC
					? { mustEndBeforeUTC: formatDateForApi(mustEndBeforeUTC) }
					: {})
			}
		},
		flagValidationErrors
	});

	return {
		ready: req.ready.then(results => {
			return {
				page: results
					? results.page.map(row => {
							const { dateStartsUTC, dateEndsJustBeforeUTC, ...rest } = row;

							return {
								...rest,
								dateStartsUTC: moment.utc(dateStartsUTC).toDate(),
								dateEndsJustBeforeUTC: moment
									.utc(dateEndsJustBeforeUTC)
									.toDate()
							};
					  })
					: []
			};
		}),
		abort: () => req.abort(),
		aborted: () => req.aborted(),
		type: req.type
	};
}
