import React, { useCallback } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import { FieldOption } from "components/FieldSelect/FieldSelect";
import ModalFieldSelect from "components/ModalFieldSelect";
import Button from "components/Button";
import { getTimezoneOptions } from "services/getTimezoneOptions";

import { useMeetingCalendarTimezone } from "./useMeetingCalendarTimezone";

const ChangeTimezoneButton: React.FunctionComponent = () => {
	const [currentTimezone, setTimezone] = useMeetingCalendarTimezone();

	const onOK = useCallback(
		(currentOptions: FieldOption[]) => {
			const selectedOption = currentOptions.find(opt => opt.selected);
			if (selectedOption) {
				if (typeof selectedOption.value !== "string") {
					throw new Error("Selected value was not a string");
				}
				setTimezone(selectedOption.value);
			}
		},
		[setTimezone]
	);

	return (
		<ModalFieldSelect
			isUnlocked
			title="New timezone"
			requireSelection
			onOK={onOK}
			options={getTimezoneOptions(currentTimezone)}
			allowMultipleSelections={false}
			editButton={({ onClick }) => (
				<Button
					isNarrow
					icon={faPen}
					label="change timezone"
					onClick={onClick}
				/>
			)}
		/>
	);
};

export default ChangeTimezoneButton;
