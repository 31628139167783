import React, { useEffect, useState } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";
import FieldText from "components/FieldText";
import { DataInAnyRow } from "components/PageDashboard/SingleSessionPricesSection/SingleSessionPricesTable/SingleSessionPricesTable";
import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { getCurrencyOptions } from "services/getCurrencyOptions";
import { FreeSessionsNote } from "./StepPrice.styles";
import { useAccountStatus } from "hooks/useAccountStatus";

function StepPrice({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const { defaultCurrencyCode } = useAccountStatus();
	const [currentPriceValue, setCurrentPriceValue] = useState<number>(0);

	const [currentCurrencyOptions, setCurrentCurrencyOptions] = useState<
		FieldOption[]
	>(getCurrencyOptions(defaultCurrencyCode));

	useEffect(() => {
		const selectedCurrencyOption = currentCurrencyOptions.find(
			currentCurrencyOptions => !!currentCurrencyOptions.selected
		);

		if (
			!(
				selectedCurrencyOption &&
				typeof selectedCurrencyOption.value === "string"
			)
		) {
			disableProgressToNextStep();
			return;
		}

		enableProgressToNextStep({
			currency: selectedCurrencyOption.value,
			price: currentPriceValue
		});
	}, [
		enableProgressToNextStep,
		disableProgressToNextStep,
		currentPriceValue,
		currentCurrencyOptions
	]);

	return (
		<>
			<FreeSessionsNote>
				For a free session, leave the price at 0
			</FreeSessionsNote>

			<FieldSelect
				allowMultipleSelections={false}
				currentOptions={currentCurrencyOptions}
				onNewCurrentOptions={setCurrentCurrencyOptions}
				useCheckboxes={false}
				isInline
				addRightMargin
			/>

			<FieldText
				subType="number"
				value={currentPriceValue}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					setCurrentPriceValue(
						e.currentTarget.value ? parseFloat(e.currentTarget.value) : 0
					);
				}}
				isInline
			/>
		</>
	);
}

export default StepPrice;
