import React, { useCallback, useRef, useMemo } from "react";
import uniqid from "uniqid";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import Button from "components/Button";
import { defaultErrorText } from "components/ErrorBanner/ErrorBanner";
import FieldCheckbox from "components/FieldCheckbox";
import {
	FieldOptions,
	ErrorMessage,
	SelectDropDown,
	Option,
	PaginationButtonContainer
} from "./FieldSelect.styles";
import OtherField from "./OtherField";
import { sorted } from "utils/sorted";

export type FieldSelectProps = {
	allowMultipleSelections?: boolean;
	isLoading?: boolean;
	isError?: boolean;
	currentOptions: FieldOption[];
	onNewCurrentOptions: React.Dispatch<React.SetStateAction<FieldOption[]>>;
	isNotValid?: boolean;
	useCheckboxes?: boolean;
	isInline?: boolean;
	addRightMargin?: boolean;
	addLeftMargin?: boolean;
	placeholder?: string;
	pagination?: {
		haveNextPage: boolean;
		onClickNextPage: () => void;
		havePreviousPage: boolean;
		onClickPreviousPage: () => void;
	};
	testid?: string;
};

export type FieldOption = {
	id: string;
	text: string;
	extra?: React.ReactNode;
	selected?: boolean;
	isScrollToDefault?: boolean;
	disabled?: boolean;

	value: string | number | null | boolean;

	/* Properties reserved for 'other' field */
	isOtherField?: boolean;
	otherFieldType?: "text" | "number";
	otherFieldMin?: number;
	otherFieldMax?: number;
	otherFieldStep?: number;
	/* / Properties reserved for 'other' field */
};

function FieldSelect({
	allowMultipleSelections = true,
	isLoading,
	isError,
	currentOptions,
	onNewCurrentOptions,
	isNotValid = false,
	useCheckboxes = true,
	isInline = false,
	addRightMargin = false,
	addLeftMargin = false,
	placeholder,
	pagination,
	testid
}: FieldSelectProps) {
	const instanceId = useRef(uniqid());

	const placeholderFieldId = "_placeholder";
	const toggleOptMulti = useCallback(
		(id: string) => {
			if (id === placeholderFieldId) {
				return;
			}
			onNewCurrentOptions(currentOptions =>
				currentOptions.map(opt =>
					opt.id === id ? { ...opt, selected: !opt.selected } : opt
				)
			);
		},
		[onNewCurrentOptions, placeholderFieldId]
	);

	const setOptsMulti = useCallback(
		(ids: string[]) => {
			onNewCurrentOptions(currentOptions =>
				currentOptions.map(opt => ({
					...opt,
					selected:
						opt.id === placeholderFieldId ? opt.selected : ids.includes(opt.id)
				}))
			);
		},
		[onNewCurrentOptions, placeholderFieldId]
	);

	const toggleOptMaxOne = useCallback(
		(id: string) => {
			if (id === placeholderFieldId) {
				return;
			}

			onNewCurrentOptions(currentOptions =>
				currentOptions.map(opt => ({
					...opt,
					selected: opt.id === id ? !opt.selected : false
				}))
			);
		},
		[onNewCurrentOptions, placeholderFieldId]
	);

	if (placeholder !== undefined && !!useCheckboxes) {
		throw new Error("Placeholders only apply to drop-down style select fields");
	}

	const optionsMarkedOther = useMemo(
		() => currentOptions.filter(opt => !!opt.isOtherField),
		[currentOptions]
	);
	if (optionsMarkedOther.length > 1) {
		throw new Error("You can only have one option marked 'isOther'");
	}
	const optionMarkedOther =
		optionsMarkedOther.length > 0 ? optionsMarkedOther[0] : undefined;

	const handleChangeToFieldMarkedOther = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (!optionMarkedOther) {
				throw new Error("There is no option marked 'other'");
			}

			// Extract the value from the event to prevent errors caused by
			// event pooling: https://legacy.reactjs.org/docs/legacy-event-pooling.html
			const eventValue = event.currentTarget.value + "";

			onNewCurrentOptions(currentOptions => {
				const newOptionsNotOther = currentOptions.filter(
					opt => !opt.isOtherField
				);

				const newOptionsOther = currentOptions
					.filter(opt => !!opt.isOtherField)
					.map(opt => {
						const isNumberField =
							!!opt.otherFieldType && opt.otherFieldType === "number";
						const value = isNumberField
							? Number(eventValue)
							: String(eventValue);
						return {
							...opt,
							value
						};
					});

				const newCurrentOptions: FieldOption[] = [
					...newOptionsNotOther,
					...newOptionsOther
				] as FieldOption[];

				return newCurrentOptions;
			});
		},
		[onNewCurrentOptions, optionMarkedOther]
	);

	const sortedOptions = useMemo(() => {
		const comp = (a: FieldOption, b: FieldOption) => {
			return !!a.isOtherField === !!b.isOtherField
				? a.text.toLowerCase().localeCompare(b.text.toLowerCase())
				: (!!a.isOtherField ? 1 : 0) - (!!b.isOtherField ? 1 : 0);
		};
		return sorted(currentOptions, comp);
	}, [currentOptions]);

	return (
		<>
			{pagination && pagination.havePreviousPage ? (
				<PaginationButtonContainer>
					<Button
						onClick={pagination.onClickPreviousPage}
						isNarrow
						icon={faArrowUp}
						label="Previous page"
					/>
				</PaginationButtonContainer>
			) : null}

			{isError ? (
				<ErrorMessage>{defaultErrorText}</ErrorMessage>
			) : isLoading ? (
				<p>Loading...</p>
			) : useCheckboxes ? (
				<FieldOptions isNotValid={isNotValid}>
					{sortedOptions.map(opt => {
						const fieldId = `checkboxopt-${opt.id}`;
						return (
							<li key={`wrapper-${fieldId}`}>
								<FieldCheckbox
									id={fieldId}
									name={
										allowMultipleSelections
											? fieldId
											: `checkbox-${instanceId.current}`
									}
									isScrollToDefault={!!opt.isScrollToDefault}
									checked={!!opt.selected}
									onChange={
										allowMultipleSelections
											? () => toggleOptMulti(opt.id)
											: () => toggleOptMaxOne(opt.id)
									}
									labelText={opt.isOtherField ? "" : opt.text}
									extra={
										<>
											{opt.extra}{" "}
											{opt.isOtherField
												? (() => {
														if (opt.value === null) {
															throw new Error(
																"'Other' fields cannot have null values"
															);
														}

														if (typeof opt.value === "boolean") {
															throw new Error(
																"'Other' fields cannot have boolean values"
															);
														}

														return (
															<OtherField
																type={opt.otherFieldType}
																text={opt.text}
																value={opt.value ? opt.value : ""}
																onChange={handleChangeToFieldMarkedOther}
																highlightValidationError={isNotValid}
															/>
														);
												  })()
												: null}
										</>
									}
									disabled={opt.disabled}
								/>
							</li>
						);
					})}
				</FieldOptions>
			) : (
				<>
					<SelectDropDown
						isInline={isInline}
						addRightMargin={addRightMargin}
						addLeftMargin={addLeftMargin}
						multiple={allowMultipleSelections}
						value={
							/* Use 'id' rather than 'value' to allow the field to correctly select the 'other' field when it is selected */ getSelectValue(
								currentOptions,
								allowMultipleSelections,
								"id"
							)
						}
						onChange={e => {
							if (allowMultipleSelections) {
								setOptsMulti(
									Array.from(e.currentTarget.selectedOptions).map(
										el => el.value
									)
								);
							} else {
								toggleOptMaxOne(e.currentTarget.selectedOptions[0].value);
							}
						}}
						isNotValid={isNotValid}
						data-testid={testid}
					>
						{(placeholder
							? addPlaceholder({
									options: sortedOptions,
									placeholder,
									placeholderFieldId
							  })
							: sortedOptions
						).map(opt => {
							if (opt.extra && typeof opt.extra !== "string") {
								throw new Error(
									"For DropDown-type select fields, any 'extra' content in options must be a string"
								);
							}
							return (
								<Option
									value={opt.id}
									data-value={opt.value}
									key={`selectopt-${opt.value}`}
									disabled={opt.disabled}
									isPlaceholder={opt.id === placeholderFieldId}
									data-testid={testid ? `${testid}-${opt.id}` : undefined}
								>
									{opt.text}
									{opt.extra ? opt.extra : null}
								</Option>
							);
						})}
					</SelectDropDown>
					{optionMarkedOther && optionMarkedOther.isOtherField
						? (() => {
								if (optionMarkedOther.value === null) {
									throw new Error("'Other' fields cannot have null values");
								}

								if (typeof optionMarkedOther.value === "boolean") {
									throw new Error("'Other' fields cannot have boolean values");
								}
								return (
									<div
										style={{
											visibility: optionMarkedOther.selected
												? "visible"
												: "hidden"
										}}
									>
										<OtherField
											type={optionMarkedOther.otherFieldType}
											text={optionMarkedOther.text}
											value={
												optionMarkedOther.value ? optionMarkedOther.value : ""
											}
											onChange={handleChangeToFieldMarkedOther}
											highlightValidationError={isNotValid}
										/>
									</div>
								);
						  })()
						: null}
				</>
			)}

			{pagination && pagination.haveNextPage ? (
				<PaginationButtonContainer>
					<Button
						onClick={pagination.onClickNextPage}
						isNarrow
						icon={faArrowDown}
						label="Next page"
					/>
				</PaginationButtonContainer>
			) : null}
		</>
	);
}

export default FieldSelect;

function getSelectValue(
	currentOptions: FieldOption[],
	allowMultipleSelections: boolean,
	useOptionProperty: keyof FieldOption = "value"
) {
	const selectedOptionValues = currentOptions
		.filter(opt => !!opt.selected)
		.map(opt => opt[useOptionProperty] + "");
	if (allowMultipleSelections) {
		return selectedOptionValues;
	}
	return selectedOptionValues.length ? selectedOptionValues[0] : undefined;
}

interface AddPlaceholderParams {
	options: FieldOption[];
	placeholder: string;
	placeholderFieldId: string;
}

function addPlaceholder({
	options,
	placeholder,
	placeholderFieldId
}: AddPlaceholderParams) {
	const placeholderFieldValue = placeholderFieldId;
	return [
		{
			text: placeholder,
			value: placeholderFieldValue,
			id: placeholderFieldId,
			disabled: false,
			extra: null
		},
		...options
	];
}
