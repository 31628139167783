import React from "react";

import { WizardRouteMap, WizardRouteMapItem } from "./StepHeader.styles";

export interface StepHeaderProps {
	stepNumber: number;
	totalNumSteps: number;
	title: string;
	wizardDescription: string | undefined;
	stepNames: string[];
}

const StepHeader: React.FunctionComponent<StepHeaderProps> = ({
	stepNumber,
	totalNumSteps,
	title,
	wizardDescription,
	stepNames
}) => {
	return (
		<>
			<WizardRouteMap>
				{" "}
				{stepNames.map((stepName, i) => (
					<WizardRouteMapItem
						key={i + stepName}
						className={i === stepNumber - 1 ? "active" : undefined}
					>
						{stepName}
					</WizardRouteMapItem>
				))}
			</WizardRouteMap>
			<h2>
				Step {stepNumber} of {totalNumSteps} : {title}
				{wizardDescription ? ` | (${wizardDescription})` : ``}
			</h2>
		</>
	);
};

export default StepHeader;
