import { formatSessionDuration } from "services/formatSessionDuration";

// TODO:WV:20250305:Load a list of presets for the user based on what they are currently using
export function getSessionDurationOptions(selectedValue?: number) {
	const options = [30, 45, 60];
	if (selectedValue && !options.includes(selectedValue)) {
		options.push(selectedValue);
	}
	options.sort();
	const presets = options.map(sessionDurationMinutes => {
		return {
			id: sessionDurationMinutes + "",
			value: sessionDurationMinutes,
			text: formatSessionDuration({ sessionDurationMinutes }),
			selected: !!selectedValue && selectedValue === sessionDurationMinutes
		};
	});

	return [
		...presets,
		{
			id: "_other",
			value: "",
			text: "Other",
			selected: false,

			isOtherField: true,
			otherFieldType: "number" as "number",
			otherFieldMin: 1,
			otherFieldMax: 60,
			otherFieldStep: 1
		}
	];
}
