import React from "react";
import moment from "moment-timezone";

import { MeetingCalendarEvent } from "components/MeetingCalendar/MeetingCalendar";

interface SelectedEventSummaryProps {
	eventToBook?: Pick<MeetingCalendarEvent, "start" | "end">;
	showEndTime?: boolean;
	timezone: string;
}

function SelectedEventSummary({
	eventToBook,
	showEndTime = true,
	timezone
}: SelectedEventSummaryProps) {
	if (!eventToBook) {
		return null;
	}
	return (
		<ul className="alt">
			<li>
				Start:{" "}
				{eventToBook
					? `${moment(eventToBook.start)
							.tz(timezone)
							.format("dddd, MMMM Do YYYY, h:mm:ss a")} (${timezone})`
					: ""}
			</li>
			{showEndTime ? (
				<li>
					End:{" "}
					{eventToBook
						? `${moment(eventToBook.end)
								.tz(timezone)
								.format("dddd, MMMM Do YYYY, h:mm:ss a")} (${timezone})`
						: ""}
				</li>
			) : null}
		</ul>
	);
}

export default SelectedEventSummary;
