import React, { useEffect, useState } from "react";

import FieldSelect from "components/FieldSelect";
import { FieldOption } from "components/FieldSelect/FieldSelect";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";

import { getSessionDurationOptions } from "services/getSessionDurationOptions";
import { DataInAnyRow } from "components/PageDashboard/SingleSessionPricesSection/SingleSessionPricesTable/SingleSessionPricesTable";

function StepSessionDuration({
	enableProgressToNextStep,
	disableProgressToNextStep
}: ModalWizardStepRenderProps<DataInAnyRow>) {
	const [currentOptions, setCurrentOptions] = useState<FieldOption[]>(
		getSessionDurationOptions()
	);

	useEffect(() => {
		const selectedOption = currentOptions.find(
			currentOptions => !!currentOptions.selected
		);

		if (!selectedOption || selectedOption.value === null) {
			disableProgressToNextStep();
			return;
		}

		if (typeof selectedOption.value === "boolean") {
			throw new Error("Selected option had a boolean value");
		}

		if (
			selectedOption &&
			selectedOption.isOtherField &&
			(selectedOption.value === "" || selectedOption.value === undefined)
		) {
			disableProgressToNextStep();
			return;
		}

		const sessionDurationMinutes =
			typeof selectedOption.value === "string"
				? parseInt(selectedOption.value)
				: selectedOption.value;

		enableProgressToNextStep({
			sessionDurationMinutes
		});
	}, [enableProgressToNextStep, disableProgressToNextStep, currentOptions]);

	return (
		<FieldSelect
			allowMultipleSelections={false}
			currentOptions={currentOptions}
			onNewCurrentOptions={setCurrentOptions}
		/>
	);
}

export default StepSessionDuration;
