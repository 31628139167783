import { useCallback } from "react";

import { SelectedDurationAndPrice } from "components/PageUserProfile/wizards/BookingWizard/BookingWizard";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useAccountStatus } from "hooks/useAccountStatus";
import { getUserProfile } from "api/getUserProfile";
import { makeDummyRequest } from "api/makeDummyRequest";

interface Params {
	mentorId?: string;
	doWait?: boolean;
	selectedDurationAndPrice: SelectedDurationAndPrice;
}

interface MediumStatus {
	available: boolean;
	currentUserHas: boolean;
	mentorHas: boolean;
	name: string;
}

export interface Output {
	["google-meet"]: MediumStatus;
	zoom: MediumStatus;
	skype: MediumStatus;
}

export type Medium = keyof Output;

export function isMedium(input: string): input is Medium {
	return ["google-meet", "zoom", "skype"].includes(input);
}

// TODO:WV:20220922:Test that this works
const zoomFreeMeetingMaxDurationMinutes = 40;

export function useAvailableMediums({
	mentorId,
	doWait = false,
	selectedDurationAndPrice
}: Params) {
	const { skypeId } = useAccountStatus();

	const { sessionDurationMinutes } = selectedDurationAndPrice;

	const cb = useCallback(
		(arg, auth) => {
			const currentUserHasSkype = !!skypeId;

			if (!mentorId) {
				return makeDummyRequest(
					{
						"google-meet": {
							available: false,
							currentUserHas: true,
							mentorHas: false,
							name: "Google Meet"
						},
						zoom: {
							available: false,
							currentUserHas: true,
							mentorHas: false,
							name: "Zoom"
						},
						skype: {
							available: false,
							currentUserHas: currentUserHasSkype,
							mentorHas: false,
							name: "Skype"
						}
					},
					"availableMediums"
				);
			}

			const req = getUserProfile({ uid: mentorId, cacheInMemory: true }, auth);
			return {
				ready: (async () => {
					const profile = await req.ready;
					if (!profile) {
						return profile;
					}

					const mentorHasZoom =
						!!profile.hasLinkedZoomAccount &&
						(sessionDurationMinutes <= zoomFreeMeetingMaxDurationMinutes ||
							profile.hasLinkedZoomAccount);

					const mentorHasSkype = !!profile.hasSkypeId;

					return {
						"google-meet": {
							available: true,
							currentUserHas: true,
							mentorHas: true,
							name: "Google Meet"
						},
						zoom: {
							available: mentorHasZoom,
							currentUserHas: true,
							mentorHas: mentorHasZoom,
							name: "Zoom"
						},
						skype: {
							available: mentorHasSkype && currentUserHasSkype,
							currentUserHas: currentUserHasSkype,
							mentorHas: mentorHasSkype,
							name: "Skype"
						}
					};
				})(),
				abort: () => {
					req.abort();
				},
				aborted: () => req.aborted(),
				type: req.type
			};
		},
		[mentorId, skypeId, sessionDurationMinutes]
	);

	return useRemoteResource(cb, doWait);
}
