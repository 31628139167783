import React, { useCallback, useMemo, useContext } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import { captureException } from "services/captureException";
import { getBooleanOptions } from "services/getBooleanOptions";
import { OutputRow as ICalFeedsToImportData } from "api/getICalFeedsToImport";
import ModalFieldText from "components/ModalFieldText";
import ModalFieldSelect from "components/ModalFieldSelect";
import { OnCompleteOutput } from "components/ModalWizard/ModalWizard";
import EditableTable from "components/EditableTable";
import { ValidationCallback } from "validation/validate";
import { useStickySort } from "hooks/useStickySort";
import StepUrl from "./NewRowWizard/StepUrl";
import StepContainsExternallyBookedMeetings from "./NewRowWizard/StepContainsExternallyBookedMeetings";
import terminology from "terminology.json";

interface ICalFeedsToImportTableProps {
	iCalFeedsToImport: ICalFeedsToImportData[];
	isLoading: boolean;
	isWaiting: boolean;
	isError: boolean;
	waitModalTitle: string;
	waitModalIsShowing: boolean;
	onEdit: (
		rows: { old?: DataInAnyRow; new?: DataInAnyRow }[]
	) => OnCompleteOutput;
}

export type DataInAnyRow = ICalFeedsToImportData;

const ICalFeedsToImportTable: React.FunctionComponent<
	ICalFeedsToImportTableProps
> = ({
	iCalFeedsToImport,
	isLoading,
	isWaiting,
	isError,
	onEdit: onEditGlobal,
	waitModalTitle,
	waitModalIsShowing
}) => {
	const onNewRowWizardComplete = useCallback(
		(data: ICalFeedsToImportData) => {
			return onEditGlobal([
				{
					new: {
						...data
					}
				}
			]);
		},
		[onEditGlobal]
	);

	const sortedICalFeedsToImport = useStickySort(
		iCalFeedsToImport,
		useCallback((a: ICalFeedsToImportData, b: ICalFeedsToImportData) => {
			return a.url.localeCompare(b.url);
		}, [])
	);

	const rows = useMemo(
		() =>
			sortedICalFeedsToImport.map(data => ({
				key: data.id,
				data,
				actionWhenEditing: {
					icon: faTrash,
					label: "Delete",
					onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.preventDefault();
						onEditGlobal([{ old: data }]);
					}
				}
			})),
		[sortedICalFeedsToImport, onEditGlobal]
	);

	const newRowWizard = useMemo(() => {
		const steps = [
			{
				key: "url",
				title: "What is the URL of this ICal feed?",
				shortTitle: "Feed URL",
				component: StepUrl
			},
			{
				key: "containsExternallyBookedMeetings",
				title: `Does this feed only contain teaching appointments?`,
				shortTitle: "Feed content type",
				component: StepContainsExternallyBookedMeetings
			}
		];
		const validateNewRowData: ValidationCallback<DataInAnyRow> = (
			data,
			{ checkField }
		) => {
			checkField("url", { type: "url" });
			checkField("containsExternallyBookedMeetings", { type: "boolean" });
		};
		return {
			steps,
			validateNewRowData,
			initialData: {}
		};
	}, []);

	const [addAlert] = useContext(AlertContext);
	const statusMessageOK = "The most recent import of this feed was successful.";
	const statusMessageWaiting =
		"The feed is waiting to be imported for the first time.  It may take up to 30 minutes.";
	const statusMessageMaxContentLengthExceeded =
		"Your feed is too large to import.  Please delete some old events.";
	const statusMessageMiscellaneousError =
		"There was a problem importing your ical feed.";

	const onClickOK = useCallback(
		() => addAlert({ title: "Feed status OK", contents: statusMessageOK }),
		[addAlert]
	);
	const onClickWaiting = useCallback(
		() =>
			addAlert({
				title: "Feed waiting for import",
				contents: statusMessageWaiting
			}),
		[addAlert]
	);
	const onClickMaxContentLengthExceeded = useCallback(
		() =>
			addAlert({
				title: "Feed too large",
				contents: statusMessageMaxContentLengthExceeded
			}),
		[addAlert]
	);
	const onClickMiscellaneousError = useCallback(
		() =>
			addAlert({
				title: "Problem importing feed",
				contents: statusMessageMiscellaneousError
			}),
		[addAlert]
	);

	return (
		<>
			<EditableTable<DataInAnyRow>
				allowDeleteRows
				rows={rows}
				onEdit={onEditGlobal}
				dataDescriptionSingular="iCal feed"
				dataDescriptionPlural="iCal feeds"
				onNew={onNewRowWizardComplete}
				newRowWizard={newRowWizard}
				isLoading={isLoading}
				isWaiting={isWaiting}
				isError={isError}
				explanation="You can add external iCal feed URLs here, to import your availability from other systems, so that it is reflected on your profile.  To see more information on the status of each feed, hold your mouse arrow over the icon in the 'status' column, or click on it.  Please note that ical feeds larger than ~1MB will not be imported."
				waitModalTitle={waitModalTitle}
				waitModalIsShowing={waitModalIsShowing}
				cols={[
					{
						key: "status",
						heading: "Status",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<span style={{ color: "#777" }}>
								{row.lastRefreshResult === "ok" ? (
									<FontAwesomeIcon
										title={statusMessageOK}
										icon={faCheck}
										size="lg"
										onClick={onClickOK}
										style={{ cursor: "pointer" }}
									/>
								) : row.lastRefreshResult === null ? (
									<FontAwesomeIcon
										title={statusMessageWaiting}
										icon={faClock}
										size="lg"
										onClick={onClickWaiting}
										style={{ cursor: "pointer" }}
									/>
								) : row.lastRefreshErrorType === "maxContentLengthExceeded" ? (
									<FontAwesomeIcon
										title={statusMessageMaxContentLengthExceeded}
										icon={faExclamationTriangle}
										size="lg"
										onClick={onClickMaxContentLengthExceeded}
										style={{ cursor: "pointer" }}
									/>
								) : (
									<FontAwesomeIcon
										title={statusMessageMiscellaneousError}
										icon={faExclamationTriangle}
										size="lg"
										onClick={onClickMiscellaneousError}
										style={{ cursor: "pointer" }}
									/>
								)}
							</span>
						)
					},
					{
						key: "url",
						heading: "URL",
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldText
								isUnlocked={isEditing}
								title="URL"
								helptext="Please enter the full URL of the feed (including https://)"
								onOK={newValue => {
									const newData = {
										...row,
										[rowKey]: newValue
									};
									onEdit([{ old: row, new: newData }]);
								}}
								value={row.url}
								formatValue={url =>
									url.substring(0, 30) + (url.length > 30 ? "..." : "")
								}
							/>
						)
					},
					{
						key: "containsExternallyBookedMeetings",
						heading: `Mainly contains teaching commitments`,
						field: ({ row, rowKey, isEditing, onEdit }) => (
							<ModalFieldSelect
								isUnlocked={isEditing}
								title={`Does this feed mainly contain teaching commitments that were booked outside ${terminology.siteName}?`}
								requireSelection
								onOK={newOptions => {
									const selectedOption = newOptions.find(val => !!val.selected);
									if (!selectedOption) {
										throw captureException(new Error("No selected option"), {
											evtType: "noSelectedOption"
										});
									}

									const newData = {
										...row,
										[rowKey]: !!selectedOption.value
									};
									onEdit([{ old: row, new: newData }]);
								}}
								options={getBooleanOptions(
									!!row.containsExternallyBookedMeetings
								)}
								allowMultipleSelections={false}
								useCheckboxes={true}
							/>
						)
					}
				]}
			/>
		</>
	);
};

export default ICalFeedsToImportTable;
