import { ValidationCallback } from "validation/validate";

// TODO:WV:20250305:This interface is used in places other than the API so it would be best to move it out of the API directory (same with its associated validator, below)
export interface PackagePrice {
	id: string;
	price: number;
	currency: string;
	sessionDurationMinutes: number;
	sessionTypeId: string;
	numMeetings: number;
}

export const flagValidationErrorsInPackagePrice: ValidationCallback<
	PackagePrice
> = (input, { checkField }) => {
	checkField("id", { type: "string" });
	checkField("price", { type: "number" });
	checkField("currency", { type: "string" });
	checkField("sessionDurationMinutes", { type: "number" });
	checkField("sessionTypeId", { type: "string" });
	checkField("numMeetings", { type: "number" });
};
