import styled from "styled-components";

export const WizardRouteMap = styled.div`
	font-size: smaller;
	color: gray;
	margin: 0 0 1em 0;
`;

export const WizardRouteMapItem = styled.span`
	&::before {
		content: " / ";
	}
	&:first-child::before {
		content: "";
	}
	&.active {
		font-weight: bold;
	}
`;
