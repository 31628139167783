import React, {
	useCallback,
	useContext,
	useState,
	useRef,
	useEffect
} from "react";
import { faComment, faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { AlertContext } from "components/AlertProvider/AlertProvider";
import {
	getMeetingRequests,
	OutputRow as MeetingRequestsData
} from "api/getMeetingRequests";
import { ApiRequest } from "api/ApiRequest";
import { respondToMeetingRequest } from "api/respondToMeetingRequest";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useHandleItemFromURL } from "hooks/useHandleItemFromURL";
import { useSendData } from "hooks/useSendData";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useStripQueryStringFromAddressBar } from "hooks/useStripQueryStringFromAddressBar";
import ErrorBanner from "components/ErrorBanner";
import ReadMoreText from "components/ReadMoreText";
import { ChatContext } from "components/Chat/ChatContext";
import MeetingSummary from "components/MeetingSummary";
import ModalConfirm from "components/ModalConfirm";
import FieldCheckbox from "components/FieldCheckbox";
import FieldText from "components/FieldText";
import Table from "components/Table";
import { formatMeetingDetailsForAttendees } from "services/formatMeetingDetailsForAttendees";
import { captureException } from "services/captureException";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { DashboardContext } from "components/PageDashboard/PageDashboard";
import { useDefaultTimezone } from "hooks/useDefaultTimezone";
import { isValidGoogleMeetLink } from "services/isValidGoogleMeetLink";
import terminology from "terminology.json";

import {
	MeetingRequestsTableContainer,
	RespondModalContents
} from "./MeetingRequestsSection.styles";

const MeetingRequestsSection: React.FunctionComponent = () => {
	const [{ isSignedIn, uid }] = useContext(AuthContext);
	const [, setDashboardContextState] = useContext(DashboardContext);

	const defaultTimezone = useDefaultTimezone();

	const [addAlert] = useContext(AlertContext);
	const [, setChatDetails] = useContext(ChatContext);

	const [repeatNum, setRepeatNum] = useState(1);
	const { isWaiting, isLoading, isError, output: events } = useRemoteResource<
		MeetingRequestsData[]
	>(
		useCallback(() => {
			if (!uid) {
				throw new Error("No UID");
			}

			const req = getMeetingRequests(
				{
					hostUid: uid,
					earliestStartDateUTC: new Date(),
					isAccepted: false,
					isRejected: false
				},
				{ uid }
			);

			return {
				...req,
				ready: req.ready.then(result => {
					if (!result) {
						return [];
					}
					return result.page;
				})
			};
		}, [uid]),
		isSignedIn === undefined,
		repeatNum,
		true
	);

	const [respondingToEvent, setRespondingToEvent] = useState<
		MeetingRequestsData | undefined
	>(undefined);
	const [eventResponse, setEventResponse] = useState<boolean | undefined>(
		undefined
	);
	const [googleMeetLink, setGoogleMeetLink] = useState<string | undefined>(
		undefined
	);

	const [eventResponseNote, setEventResponseNote] = useState<
		string | undefined
	>(undefined);

	const eventResponseApiRequest = useRef<ApiRequest<unknown> | undefined>(
		undefined
	);
	const [
		eventResponseApiRequestDispatchTime,
		setEventResponseApiRequestDispatchTime
	] = useState<number | undefined>(undefined);
	useEffect(() => {
		const { current: req } = eventResponseApiRequest;
		if (eventResponseApiRequestDispatchTime && req) {
			req.ready.then(response => {
				addAlert({ contents: "Response received!  Thank you" });

				setEventResponse(undefined);
				setEventResponseNote(undefined);
				setRespondingToEvent(undefined);
				setRepeatNum(new Date().getTime());
				setDashboardContextState(oldState => ({
					...oldState,
					meetingsSectionRefreshNum: new Date().getTime()
				}));
			});

			return () => {
				req.abort();
			};
		}
	}, [
		addAlert,
		eventResponseApiRequest,
		eventResponseApiRequestDispatchTime,
		setEventResponse,
		setEventResponseNote,
		setRespondingToEvent,
		setRepeatNum,
		setDashboardContextState
	]);

	const stripQueryStringFromAddressBar = useStripQueryStringFromAddressBar();
	useHandleItemFromURL<MeetingRequestsData>({
		key: "meetingrequest",
		itemsList: {
			isWaiting,
			isLoading,
			isError,
			items: events
		},
		handleFoundItem: useCallback(
			event => {
				if (!("hostUid" in event)) {
					throw captureException(new Error("Meeting in URL lacks hostUid"), {
						evtType: "meetingInURLNoHostUid",
						extra: { eventInURL: event }
					});
				} else {
					setRespondingToEvent(event);
				}
				stripQueryStringFromAddressBar();
			},
			[setRespondingToEvent, stripQueryStringFromAddressBar]
		)
	});

	const history = useHistory();

	const sendResponse = useSendData({ method: respondToMeetingRequest });

	const {
		_populated: accountStatusPopulated,
		defaultGoogleMeetLink
	} = useAccountStatus();

	useEffect(() => {
		if (!googleMeetLink && defaultGoogleMeetLink) {
			setGoogleMeetLink(defaultGoogleMeetLink);
		}
	}, [googleMeetLink, setGoogleMeetLink, defaultGoogleMeetLink]);

	if (!accountStatusPopulated) {
		return null;
	}

	const cols = [
		{ key: "startDate", heading: "Date" },
		{ key: "startTime", heading: "From" },
		{ key: "endTime", heading: "Until" },
		{ key: "timezone", heading: "Timezone" },
		{ key: "partner", heading: "With" },
		{ key: "medium", heading: "Using" },
		{ key: "message", heading: "Message" }
	];

	const rows =
		events && events.length && uid
			? events.map(event => {
					const {
						hostUid,
						startDate,
						startTime,
						endTime,
						timezone,
						partnersName,
						partnersUid,
						medium
					} = formatMeetingDetailsForAttendees(
						event,
						uid,
						true,
						defaultTimezone
					);

					return {
						data: {
							startDate,
							startTime,
							endTime,
							timezone,
							partner: (
								<Link to={`/user-id/${partnersUid}`}>{partnersName}</Link>
							),
							medium,
							message: event.privateMessageContents ? (
								<>
									{" "}
									<ReadMoreText
										text={event.privateMessageContents}
										max={20}
										onClick={() => {
											setChatDetails({
												partner: {
													uid: partnersUid,
													displayName: partnersName
												},
												pagination: {
													cursor: event.privateMessageDatesentUTC
														? new Date(
																event.privateMessageDatesentUTC.getTime() + 1
														  )
														: undefined,
													cursorType: "after"
												},
												highlightedMessageId: event.privateMessageId
											});
										}}
									/>{" "}
								</>
							) : null
						},
						action: {
							onClick: () => setRespondingToEvent(event),
							label: "Respond"
						},
						menu: [
							{
								onClick: () =>
									setChatDetails({
										partner: { uid: partnersUid, displayName: partnersName },
										pagination: {
											cursorType: "after"
										}
									}),
								label: "Open chat",
								icon: faComment
							},
							...(hostUid === uid
								? []
								: [
										{
											onClick: () => {
												history.push(`/user-id/${partnersUid}`);
											},
											label: "View profile",
											icon: faIdBadge
										}
								  ])
						]
					};
			  })
			: [];

	return (
		<>
			<ModalConfirm
				isOpen={!!respondingToEvent}
				title="Invitation"
				onCancel={() => {
					setEventResponse(undefined);
					setEventResponseNote(undefined);
					setRespondingToEvent(undefined);
				}}
				OKButtonIsDisabled={
					respondingToEvent &&
					eventResponse === true &&
					respondingToEvent.medium === "google-meet" &&
					(!googleMeetLink || !isValidGoogleMeetLink(googleMeetLink))
				}
				onOK={async () => {
					if (eventResponse === undefined) {
						addAlert({ contents: "Please indicate 'yes' or 'no'" });
						return;
					}

					if (!respondingToEvent) {
						throw new Error("No event to accept");
					}

					if (
						respondingToEvent.medium === "google-meet" &&
						eventResponse === true
					) {
						if (!googleMeetLink) {
							throw new Error("No Google Meet Link");
						}
						if (!isValidGoogleMeetLink(googleMeetLink)) {
							throw new Error("Invalid Google Meet Link");
						}
					}

					eventResponseApiRequest.current = sendResponse(
						{
							id: respondingToEvent.id,
							response: eventResponse ? true : false,
							...(respondingToEvent.medium === "google-meet" &&
							eventResponse === true
								? {
										googleMeetLink
								  }
								: {}),
							...(eventResponseNote ? { message: eventResponseNote } : {})
						},
						{ uid }
					);

					setEventResponseApiRequestDispatchTime(new Date().getTime());
				}}
				okText="Send response"
			>
				{respondingToEvent && uid
					? (() => {
							return (
								<RespondModalContents>
									<MeetingSummary event={respondingToEvent} currentUid={uid} />
									<h3>Can you make this?</h3>
									<form>
										<div className="row gtr-uniform">
											<div className="col-6 col-12-small">
												<FieldCheckbox
													id="response-decline"
													name="response-decline"
													checked={eventResponse === false}
													labelText="No"
													onChange={e =>
														setEventResponse(
															e.currentTarget.checked ? false : eventResponse
														)
													}
												/>
											</div>

											<div className="col-6 col-12-small">
												<FieldCheckbox
													id="response-accept"
													name="response-accept"
													checked={eventResponse === true}
													labelText="Yes"
													onChange={e =>
														setEventResponse(
															e.currentTarget.checked ? true : eventResponse
														)
													}
												/>
											</div>

											<div className="col-12">
												<textarea
													placeholder="Accompanying note"
													value={eventResponseNote}
													onChange={e =>
														setEventResponseNote(e.currentTarget.value)
													}
												/>
											</div>

											{respondingToEvent.medium === "google-meet" ? (
												<div className="col-12">
													<h3>Google Meet Link</h3>
													<p>
														Because this is being held over Google Meet, if you
														accept the invitation, you need to{" "}
														<a
															href="https://meet.google.com"
															target="_blank"
															rel="noopener noreferrer"
														>
															go to Google Meet in a new tab
														</a>
														, generate a meeting link ('new meeting &gt; create
														a meeting for later'), and copy/paste it back here.
													</p>

													<FieldText
														value={googleMeetLink ? googleMeetLink : ""}
														onChange={e =>
															setGoogleMeetLink(e.currentTarget.value)
														}
														placeholder="https://meet.google.com..."
														highlightValidationError={
															!!googleMeetLink &&
															!isValidGoogleMeetLink(googleMeetLink)
														}
													/>
												</div>
											) : null}
										</div>
									</form>
								</RespondModalContents>
							);
					  })()
					: null}
			</ModalConfirm>

			{isError ? <ErrorBanner /> : null}
			{!isError ? (
				<MeetingRequestsTableContainer isLoading={isLoading || isWaiting}>
					<Table
						cols={cols}
						rows={rows}
						emptyText={`There are no ${terminology.meetingRequests} that need your attention`}
						isLoading={isLoading || isWaiting}
					/>
				</MeetingRequestsTableContainer>
			) : null}
		</>
	);
};

export default MeetingRequestsSection;
